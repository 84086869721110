import { Grid, Typography, Card, CardContent } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import Spinner from "../../components/Spinner";
import { useSnackbar } from "../../utils";
import { getR4alzStats } from "../../api";

const R4AlzStats = () => {
    const { error } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [r4alzStats, setR4alzStats] = useState([]);
    const padd = 1;
    const heade = "";

    const fetchData = useCallback(
        async () => {
            setIsLoading(true);
            try {
                const { success: successCode, stats: stats } = await getR4alzStats();
                if (successCode) {
                    setR4alzStats(stats);
                } else {
                    error();
                }
            } catch (_error) {
                console.log(_error)
                error();
            }

            setIsLoading(false);
        },
        []);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [fetchData]);

    return (
        <>
            <Spinner open={isLoading} />
            <Grid
                container
                display="flex"
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                {(r4alzStats.exercises &&
                    <>
                        <Grid item mt={1} width="100%" display="flex">
                            <Grid item xs={3} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Total exercises
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.exercises.total}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Total Sessions
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.sessions.total}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercises last week
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.exercises.last_week}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercises last day
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.exercises.last_day}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid item width="100%" display="flex">
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 1 submissions
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex1.total}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 1 submissions last week
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex1.last_week}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 1 submissions last day
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex1.last_day}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid item width="100%" display="flex">
                            <Grid item xs={3} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 2 submissions
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex2.total}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 2 submissions (total)
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex2_all.total}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 2 submissions last week
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex2.last_week}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 2 submissions last day
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex2.last_day}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid item width="100%" display="flex">
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 3 submissions
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex3.total}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 3 submissions last week
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex3.last_week}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 3 submissions last day
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex3.last_day}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid item width="100%" display="flex">
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 4 submissions
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex4.total}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 4 submissions last week
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex4.last_week}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} p={padd}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant={heade} component="div">
                                            Exercise 4 submissions last day
                                        </Typography>
                                        <Typography variant="h3" textAlign={"center"}>
                                            {r4alzStats.ex4.last_day}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

export default memo(R4AlzStats);
