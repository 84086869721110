import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import Image from "mui-image";
import { ExpandMore } from "@mui/icons-material";
import queryString from "query-string";

import { jwt } from "../utils";
import Accordion from "./Accordion";
import inspectionIcon from "../assets/icons/inspection.png";
import r4alzIcon from "../assets/icons/r4alzIcon.png";
import isselServicesIcon from "../assets/icons/isselServices.png";
import usersRoundIcon from "../assets/icons/usersRound.png";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		height: "100%",
		backgroundColor: theme.palette.primaryDark.main,
		position: "fixed",
	},
	sidebar: {
		height: "calc(100% - 160px)",
		position: "fixed",
		top: "130px",
		marginLeft: "25px",
		backgroundColor: theme.palette.third.main,
		color: "white",
		borderTopRightRadius: "30px",
		borderTopLeftRadius: "30px",
		borderBottomRightRadius: "400px",
		background: "linear-gradient(to bottom, #04598c 50%, #193256 100%)"
	},
	mainBox: {
		padding: "10px 20px",
		overflow: "auto",
		position: "absolute",
		display: "flex",
		height: "calc(100% - 100px)",

	},
}));

const maybeSetToken = (Component) => (props) => {
	const { search } = useLocation();
	const { token } = queryString.parse(search);
	if (token) jwt.setToken(token);
	return <Component {...props} />;
};

const ButtonWithText = ({ text, icon, more, handler }) => (
	<span key={text}>
		{!more
			&& (
				<Button key={text} onClick={(event) => handler(event)} sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "10px 40px 0px 16px" }}>
					<Image src={icon} alt={text} fit="contain" width="25px" />
					<Typography align="center" color="white.main" fontSize="medium" ml={1} display="flex" alignItems="center" sx={{ textTransform: 'capitalize' }}>
						{text}
						{more && <ExpandMore />}
					</Typography>
				</Button>
			)}
		{more
			&& (
				<Accordion
					key={text}
					useShadow={false}
					title={
						<Grid item sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
							<Image src={icon} alt={text} fit="contain" width="25px" />
							<Typography align="center" color="white.main" fontSize="medium" ml={1} display="flex" alignItems="center" sx={{ textTransform: 'capitalize' }}>
								{text}
							</Typography>
						</Grid>
					}
					content={(
						<Grid container flexDirection="column" width="100%" justifyItems={"flex-start"} >
							{more.map((el) => (
								<Button key={el.title} onClick={el.handler} color="white" justifyItems="flex-start" sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 5px 0px 46px" }}>
									<Typography sx={{ textTransform: 'capitalize' }}>&ndash; {el.title}</Typography>
								</Button>
							))}
						</Grid>
					)}
					alwaysExpanded={true}
					titleBackground="transparent"
					expandIconColor="white"
				/>
			)}
	</span>
);

const ButtonSimple = ({ text, icon, handler, ind }) => (
	<>
		<Button key={text} onClick={(event) => handler(event)} sx={{ minWidth: "30px!important", padding: "0px", marginTop: (ind === 0) ? "0px" : "10px" }}>
			<Image src={icon} alt={text} fit="contain" width="30px" />
		</Button>
	</>
);

const Protected = ({ c }) => {
	const [isSmall, setIsSmall] = useState(window.innerWidth < 900);
	const location = useLocation();
	const navigate = useNavigate();
	const classes = useStyles();
	const [anchorElServices, setAnchorElServices] = useState(null);

	const isMenuOpenServices = Boolean(anchorElServices);
	const handleServicesMenuOpen = (event) => setAnchorElServices(event.currentTarget);
	const handleServicesMenuClose = () => { setAnchorElServices(null); };

	useEffect(() => {
		const onResize = () => setIsSmall(window.innerWidth < 900);
		window.addEventListener("resize", onResize);

		return () => window.removeEventListener("resize", onResize);
	}, []);

	const buttons = [
		// {
		// 	icon: inspectionIcon,
		// 	text: "Inspection",
		// 	handler: () => {
		// 		handleServicesMenuClose();
		// 		navigate('/inspection');
		// 	},
		// },
		// {
		// 	icon: inspectionIcon,
		// 	text: "PM2",
		// 	handler: () => {
		// 		handleServicesMenuClose();
		// 		navigate('/inspectionpm2');
		// 	},
		// },
		{
			icon: isselServicesIcon,
			text: "Issel Services",
			handler: (event) => {
				handleServicesMenuClose();
				handleServicesMenuOpen(event);
			},
			more: [
				{ title: "Users", handler: () => navigate("/issel-services/users") },
				{ title: "Services", handler: () => navigate("/issel-services/services") },
				{ title: "Hacking", handler: () => navigate("/issel-services/hacking") },
			]
		},
		{
			icon: r4alzIcon,
			text: "R4Alz",
			handler: (event) => {
				handleServicesMenuClose();
				handleServicesMenuOpen(event);
			},
			more: [
				{ title: "Stats", handler: () => navigate("/r4alz/stats") },
				{ title: "Submissions", handler: () => navigate("/r4alz/submissions") },
			]
		},
		{
			icon: inspectionIcon,
			text: "Lab",
			handler: (event) => {
				handleServicesMenuClose();
				handleServicesMenuOpen(event);
			},
			more: [
				{ title: "Users", handler: () => navigate("/lab/users") },
				{ title: "Equipment", handler: () => navigate("/lab/equipment") },
			]
		},
		{
			icon: usersRoundIcon,
			text: "Users",
			handler: () => {
				handleServicesMenuClose();
				navigate('/users');
			},
		},
	];

	const renderServicesMenu = (
		<Menu
			keepMounted
			anchorEl={anchorElServices}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuOpenServices}
			onClose={handleServicesMenuClose}
		>
			{buttons.find((button) => button.text === "Issel Services").more.map((moreButton) => (
				<MenuItem key={moreButton.title} justifyContent={"flex-between"} onClick={() => { handleServicesMenuClose(); moreButton.handler(); }}>
					<p style={{ marginLeft: "5px" }}>{moreButton.title}</p>
				</MenuItem>
			))}
		</Menu>
	);

	return jwt.isAuthenticated()
		? (
			<div className={classes.main}>
				<div className={classes.sidebar}
					style={{
						width: (isSmall) ? "50px" : "200px",
						padding: (isSmall) ? "20px 5px" : "20px 5px",
						textAlign: "center",
						boxShadow: "2px 2px 14px #262835"

					}}>
					{!isSmall && buttons.map((button) => (
						<ButtonWithText
							key={button.text}
							icon={button.icon}
							text={button.text}
							handler={button.handler}
							more={button.more}
						/>
					))}
					{isSmall && buttons.map((button, ind) => (
						<ButtonSimple
							key={button.text}
							icon={button.icon}
							text={button.text}
							handler={button.handler}
							more={button.more}
							ind={ind}
						/>
					))}
					{renderServicesMenu}
				</div>
				<div className={classes.mainBox} style={{ width: (isSmall) ? "calc(100% - 50px)" : "calc(100% - 200px)", marginLeft: (isSmall) ? "50px" : "200px" }}>
					<div className="header-container">
						{c}
					</div>
				</div>
			</div>
		)
		: <Navigate replace to="/" state={{ from: location }} />;
};

Protected.propTypes = { c: PropTypes.node.isRequired };
Protected.whyDidYouRender = true;

export default maybeSetToken(Protected);

