import { Grid, Typography, Divider, Autocomplete, ToggleButton, Dialog, TextField, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { memo, useCallback, useEffect, useState } from "react";

import Accordion from "../../components/Accordion";
import { SecondaryBackgroundButton, PrimaryBorderButton, SecondaryBorderButton, PrimaryBackgroundButton } from "../../components/Buttons";
import Popup from "../../components/Popup";
import Search from "../../components/Search";
import Spinner from "../../components/Spinner";
import { isFuzzyMatch, useSnackbar, dayjs } from "../../utils";
import { getEquipmentData, getLabUsers, addNewEquipment, editEquipment, removeEquipment, lendEquipment, getEquipmentUsers } from "../../api";

const LabEquipment = () => {
    const { error, success } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [searchFilter, setSearchFilter] = useState("");
    const [equipment, setEquipment] = useState([]);
    const [filteredEquipment, setFilteredEquipment] = useState(equipment);
    const [deleteEquipment, setDeleteEquipment] = useState({ id: null, name: null });

    const [selectingEquipment, setSelectingEquipment] = useState(false)
    const [selectedEquipment, setSelectedEquipment] = useState({});

    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [phoneOptions, setPhoneOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    const PopupModes = {
        Add: "add",
        View: "view",
        Edit: "edit",
        Lend: "lend",
        Bind: "bind",
        Exchange: "exchange",
        Close: null,
    };

    const [popupOpen, setPopupOpen] = useState(null);

    const [tempEquipmentUsers, setTempEquipmentUsers] = useState([]);

    const [tempEquipment, setTempEquipment] = useState({
        name: "",
        manufacturer: "",
        position: "",
        quantity: "",
        availableQuantity: "",
        price: "",
        description: "",
    });

    const [tempEquipmentErrors, setTempEquipmentErrors] = useState({
        name: "",
        manufacturer: "",
        position: "",
        quantity: "",
        price: "",
        description: "",
    });

    const [tempLendEquipment, setTempLendEquipment] = useState({
        fullName: "",
        phone: "",
        lentAt: "",
        returnAt: "",
        duration: "",
        description: "",
    });

    const [tempLendEquipmentErrors, setTempLendEquipmentErrors] = useState({
        fullName: "",
        phone: "",
        lentAt: "",
        returnAt: "",
        duration: "",
        description: "",
    });

    const [tempBindEquipment, setTempBindEquipment] = useState({
        name: "",
        quantity: "",
        maxQuantity: "",
    });

    const [tempBindEquipmentErrors, setTempBindEquipmentErrors] = useState({
        quantity: "",
    });

    const inputStyle = {
        color: "black",
        width: "100%",
        backgroundColor: "white",
        opacity: 0.7,
        borderRadius: "4px",
        margin: "0px",
        marginBottom: "10px",
    };

    const fetchData = useCallback(
        async () => {
            setIsLoading(true);

            try {
                const { success: equipmentSuccess, equipment: equip } = await getEquipmentData();
                if (equipmentSuccess) {
                    setEquipment(equip);

                    setManufacturerOptions(equip.map(({ manufacturer }) => manufacturer))
                } else {
                    error();
                }

                const { success: userSuccess, users: u } = await getLabUsers();
                if (userSuccess) {
                    const names = u.map((u) => u.fullName)
                    const phones = u.map((u) => String(u.phone))
                    setUserOptions(names);
                    setPhoneOptions(phones);
                } else {
                    error();
                }
            } catch (_error) {
                error();
            }

            setIsLoading(false);
        }, []
    );

    const onPopupOpen = async (mode, equipmentName = undefined) => {
        switch (mode) {
            case PopupModes.Add:
                setTempEquipment({
                    name: "",
                    manufacturer: "",
                    position: "",
                    quantity: "",
                    availableQuantity: "",
                    price: "",
                    description: "",
                })

                break;
            case PopupModes.Edit:
            case PopupModes.View:
                const viewEquipmentToSelect = filteredEquipment.find((e) => e.name === equipmentName);

                setTempEquipment({
                    ...viewEquipmentToSelect,
                });

                const r = await getEquipmentUsers(equipmentName);

                break;
            case PopupModes.Lend:
                setTempLendEquipment({
                    fullName: "",
                    phone: "",
                    lentAt: new Date(),
                    returnAt: new Date(),
                    duration: "",
                    description: "",
                });

                setSelectedEquipment({});

                break;
            case PopupModes.Bind:
                const bindEquipmentToSelect = equipment.find((e) => e.name === equipmentName);

                if (bindEquipmentToSelect) {
                    setTempBindEquipment({
                        name: bindEquipmentToSelect.name,
                        quantity: 1,
                        maxQuantity: (bindEquipmentToSelect.availableQuantity - 1),
                    });
                } else {
                    return;
                }

                break;
            case PopupModes.Exchange:
                if (selectedEquipment && selectedEquipment.hasOwnProperty(equipmentName)) {
                    const equipmentToExchange = selectedEquipment[equipmentName];

                    setTempBindEquipment({
                        name: equipmentName,
                        quantity: equipmentToExchange.quantity,
                        maxQuantity: equipmentToExchange.maxQuantity,
                    });
                } else {
                    return;
                }

                break;
            default:
                break;
        }

        setTempEquipmentErrors({
            name: "",
            manufacturer: "",
            position: "",
            quantity: "",
            price: "",
            description: "",
        });

        setTempLendEquipmentErrors({
            fullName: "",
            phone: "",
            duration: "",
            description: "",
        });

        setTempBindEquipmentErrors({
            quantity: "",
        });

        setPopupOpen(mode);
    };

    const onAutocompleteName = (event, name) => {
        const nameIndex = userOptions.findIndex((o) => o === name);

        if (name) {
            setTempLendEquipment({
                ...tempLendEquipment,
                fullName: name,
                phone: phoneOptions[nameIndex],
            });
        } else {
            setTempLendEquipment({
                ...tempLendEquipment,
                fullName: "",
                phone: "",
            });
        }
    };

    const onAutocompletePhone = (event, phone) => {
        const phoneIndex = phoneOptions.findIndex((o) => o === phone);

        if (phone) {
            setTempLendEquipment({
                ...tempLendEquipment,
                fullName: userOptions[phoneIndex],
                phone: phone,
            });
        } else {
            setTempLendEquipment({
                ...tempLendEquipment,
                fullName: "",
                phone: "",
            });
        }
    };

    const onStartSelectEquipment = () => {
        let errorsFound = false;
        const lendEquipmentFieldErrors = {
            fullName: "",
            phone: "",
            duration: "",
            description: "",
        };

        if (tempLendEquipment.fullName === "") {
            errorsFound = true;
            lendEquipmentFieldErrors.fullName = "User Name cannot be empty.";
        }

        const phoneRegex = /^69\d{8}$/;
        if (tempLendEquipment.phone === "") {
            errorsFound = true;
            lendEquipmentFieldErrors.phone = "User Phone cannot be empty.";
        } else if (!phoneRegex.test(tempLendEquipment.phone)) {
            errorsFound = true;
            lendEquipmentFieldErrors.phone = "User Phone is invalid. It must follown the format (69)XXXXXXXX.";
        }

        if (tempLendEquipment.duration === "") {
            errorsFound = true;
            lendEquipmentFieldErrors.duration = "Lend Duration cannot be empty.";
        } else if (tempLendEquipment.duration <= 0) {
            errorsFound = true;
            lendEquipmentFieldErrors.duration = "Lend Duration must be a positive number.";
        }

        if (tempLendEquipment.description === "") {
            errorsFound = true;
            lendEquipmentFieldErrors.description = "Lend Description cannot be empty.";
        }

        if (errorsFound) {
            setTempLendEquipmentErrors(lendEquipmentFieldErrors);

            return;
        }


        setPopupOpen(PopupModes.Close);
        setSelectingEquipment(true);
    };

    const onCancelSelection = () => {
        setSelectingEquipment(false);

        const oldEquipment = equipment;

        Object.keys(selectedEquipment).forEach((e) => {
            const currentEquipment = oldEquipment.find((old) => old.name === e);

            currentEquipment.availableQuantity += e.quantity;
        });

        setEquipment(oldEquipment);
        setSelectedEquipment({});
    };

    const onInspectUser = (userName) => {
        const domainName = window.location.hostname;
        const port = window.location.port || (window.location.protocol === 'https:' ? '443' : '80');
        const userBase64 = btoa(userName)

        console.log(`${domainName}:${port}/lab/users?userId=${userBase64}`)

        window.open(`${domainName}:${port}/lab/users?userId=${userBase64}`);
    };

    const onFinishSelection = () => {
        setSelectingEquipment(false);
        setPopupOpen(PopupModes.Lend);
    };

    const equipmentIsSelected = (equipmentName) => {
        return selectedEquipment.hasOwnProperty(equipmentName);
    };

    const isEquipmentAvailable = (equipment) => {
        return (equipment.availableQuantity > 0);
    };

    const onDeselectEquipment = (equipmentName) => {
        const currentSelectedEquipment = { ...selectedEquipment };

        if (equipmentIsSelected(equipmentName)) {
            const elementIndex = equipment.findIndex((e) => e.name === equipmentName);
            const updatedEquipment = equipment;

            updatedEquipment[elementIndex].availableQuantity += parseInt(currentSelectedEquipment[equipmentName].quantity);

            setEquipment(updatedEquipment)

            delete currentSelectedEquipment[equipmentName];
            setSelectedEquipment(currentSelectedEquipment);
        }
    };

    const onExchangeEquipment = (quantity) => {
        let newQuantity = parseInt(quantity);

        if (newQuantity === NaN) {
            return;
        }

        const totalEquipment = tempBindEquipment.quantity + tempBindEquipment.maxQuantity;

        if (quantity <= 0) {
            newQuantity = 1;
        }

        if (quantity > totalEquipment) {
            newQuantity = totalEquipment;
        }

        setTempBindEquipment({
            ...tempBindEquipment,
            quantity: newQuantity,
            maxQuantity: (totalEquipment - newQuantity),
        });
    };

    const onBindEquipment = () => {
        const elementIndex = equipment.findIndex((e) => e.name === tempBindEquipment.name);
        const updatedEquipment = equipment;
        updatedEquipment[elementIndex].availableQuantity = tempBindEquipment.maxQuantity;

        setEquipment(updatedEquipment);

        setSelectedEquipment({
            ...selectedEquipment,
            [tempBindEquipment.name]: {
                quantity: tempBindEquipment.quantity,
                maxQuantity: updatedEquipment[elementIndex].availableQuantity,
            },
        });

        setPopupOpen(PopupModes.Close);
    };

    const onTempEquipmentChange = (name, value) => {
        if (name === "quantity") {
            setTempEquipment({
                ...tempEquipment,
                [name]: value,
                availableQuantity: value,
            });
        } else {
            setTempEquipment({
                ...tempEquipment,
                [name]: value,
            });
        }


        if (value !== "") {
            setTempEquipmentErrors({
                ...tempEquipmentErrors,
                [name]: "",
            });
        }
    };

    const onTempLendEquipmentChange = (name, value) => {
        setTempLendEquipment({
            ...tempLendEquipment,
            [name]: value,
        });

        if (value !== "") {
            setTempLendEquipmentErrors({
                ...tempLendEquipmentErrors,
                [name]: "",
            });
        }
    };

    const onTempBindEquipmentChange = (name, value) => {
        setTempBindEquipment({
            ...tempBindEquipment,
            [name]: value,
        });

        if (value !== "") {
            setTempBindEquipmentErrors({
                ...tempBindEquipmentErrors,
                [name]: "",
            });
        }
    };

    const onDelete = async () => {
        setIsLoading(true);

        const { success: successCode } = await removeEquipment(deleteEquipment?.id);
        if (successCode) {
            success("Equipment deleted!");
        } else {
            error();
        }

        setDeleteEquipment({ id: null, name: null });
        await fetchData();
        setIsLoading(false);
    };

    const onEquipmentChange = async () => {
        setIsLoading(true);

        let errorsFound = false;
        const equipmentFieldErrors = {
            name: "",
            manufacturer: "",
            position: "",
            quantity: "",
            price: "",
        };

        if (tempEquipment.name === "") {
            errorsFound = true;
            equipmentFieldErrors.name = "Equipment name cannot be empty.";
        }

        if (tempEquipment.manufacturer === "") {
            errorsFound = true;
            equipmentFieldErrors.manufacturer = "Equipment manufacturer cannot be empty.";
        }

        if (tempEquipment.position === "") {
            errorsFound = true;
            equipmentFieldErrors.position = "Equipment position cannot be empty.";
        }

        const quantityRegex = /^\d+$/;
        if (tempEquipment.quantity === "") {
            errorsFound = true;
            equipmentFieldErrors.quantity = "Equipment quantity cannot be empty.";
        } else if (!quantityRegex.test(tempEquipment.quantity)) {
            errorsFound = true;
            equipmentFieldErrors.quantity = "Equipment quantity must be a positive integer.";
        }

        const priceRegex = /^(?:\d+(?:\.\d*)?|\.\d+)?$/;
        if (tempEquipment.price === "") {
            errorsFound = true;
            equipmentFieldErrors.price = "Equipment price cannot be empty.";
        } else if (!priceRegex.test(tempEquipment.price)) {
            errorsFound = true;
            equipmentFieldErrors.price = "Equipment price cannot be a negative number";
        }

        if (errorsFound) {
            setTempEquipmentErrors(equipmentFieldErrors);
            setIsLoading(false);

            return;
        }

        const { success: successCode } = (popupOpen === PopupModes.Edit) ? await editEquipment(tempEquipment) : await addNewEquipment(tempEquipment);
        if (successCode) {
            success("Equipment added succesfully!");
        } else {
            error();
        }

        setPopupOpen(PopupModes.Close);

        await fetchData();

        setIsLoading(false);
    }

    const onLendEquipment = async () => {
        setIsLoading(true);

        const data = {
            group: tempLendEquipment,
            equipment: selectedEquipment,
        };

        const { success: successCode } = await lendEquipment(data);
        if (successCode) {
            success("Equipment Lent succesfully!");
            await fetchData();
        } else {
            error();
        }

        setPopupOpen(PopupModes.Close);
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [fetchData]);

    useEffect(() => {
        if (tempLendEquipment.returnAt !== "") {
            let returnAt = new Date(tempLendEquipment.lentAt);
            if (tempLendEquipment.duration !== "") {
                returnAt.setMonth(returnAt.getMonth() + parseInt(tempLendEquipment.duration));
            }

            setTempLendEquipment({
                ...tempLendEquipment,
                returnAt,
            });
        }
    }, [tempLendEquipment.duration]);

    useEffect(() => {
        setFilteredEquipment(equipment.filter((eq) =>
            isFuzzyMatch(eq.name, searchFilter)
            || isFuzzyMatch(eq.manufacturer, searchFilter)
            || isFuzzyMatch(eq.lastUpdatedAt, searchFilter)
        ));
    }, [searchFilter, equipment]);

    return (
        <>
            <Spinner open={isLoading} />
            <Grid
                container
                display="flex"
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    container
                    item
                    width="100%"
                    mt={2}
                    p={1}
                    pr={2}
                    mb={2}
                    display="flex"
                    minHeight="60px"
                    borderRadius={"20px"}
                    alignItems={"center"}
                    sx={{
                        background: "linear-gradient(to left, #04598c 0%, #193256 100%)",
                        boxShadow: "10px 3px 10px -5px #262835",
                    }}
                >
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                        justifyContent="center"
                        sx={{
                            borderRadius: "30px 0px 30px 0px",
                            borderTopLeftRadius: "30px",
                            boxShadow: "2px 2px 14px #262835",
                            background: "linear-gradient(to right, #04598c 0%, #193256 150%)"
                        }}
                    >
                        <SettingsSuggestIcon fontSize="large" color="white" />
                    </Grid>
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                    >
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Search value={searchFilter} placeholder="Search by Name" onChange={(event) => setSearchFilter(event.target.value)} />
                    </Grid>
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                    >
                    </Grid>
                    <Grid
                        item
                        xs={3.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        {selectingEquipment && (
                            <Grid item xs={12} textAlign="center">
                                <Typography sx={{ color: "#ffffff", fontSize: "17px" }}>Note: Search & Select the Equipment for Lending!</Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        pr={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        height="100%"
                    >
                        {!selectingEquipment && (
                            <>
                                <PrimaryBorderButton title="Lend Equipment" width="160px" onClick={() => onPopupOpen(PopupModes.Lend)} />
                                <PrimaryBorderButton title="Add Equipment" width="160px" onClick={() => onPopupOpen(PopupModes.Add)} />
                            </>
                        )}
                        {selectingEquipment && (
                            <>
                                <SecondaryBorderButton title="Cancel Selection" width="160px" onClick={() => onCancelSelection()} />
                                <PrimaryBorderButton title="Finish Selection" width="160px" onClick={() => onFinishSelection()} />
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item mt={2} width="100%">
                    <Accordion
                        alwaysExpanded
                        title={(
                            <Grid
                                container
                                display="flex"
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <Typography>Lab Equipment</Typography>
                                </Grid>
                            </Grid>
                        )}
                        subtitle={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={2}>
                                    <Typography>Name</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Manufacturer</Typography>
                                </Grid>
                                <Grid item xs={1.5} textAlign="center">
                                    <Typography>Last Updated At</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Position</Typography>
                                </Grid>
                                <Grid item xs={1.5} textAlign="center">
                                    <Typography>Owned</Typography>
                                </Grid>
                                <Grid item xs={1.5} textAlign="center">
                                    <Typography>Available</Typography>
                                </Grid>
                                <Grid item xs={1.5} textAlign="center">
                                    <Typography>Actions</Typography>
                                </Grid>
                            </Grid>
                        )}
                        content={(
                            <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                {filteredEquipment.map((us, ind) => (
                                    <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                        {ind !== 0
                                            && (
                                                <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                    <Divider style={{ width: "100%" }} />
                                                </Grid>
                                            )}
                                        <Grid key={`row_${ind}`} container item alignItems="center">
                                            <Grid item xs={2}>
                                                <Typography>{us.name}</Typography>
                                            </Grid>
                                            <Grid item xs={2} textAlign="center">
                                                <Typography>{us.manufacturer}</Typography>
                                            </Grid>
                                            <Grid item xs={1.5} textAlign="center">
                                                <Typography>{dayjs(us.lastUpdatedAt).format('DD/MM/YYYY HH:mm')}</Typography>
                                            </Grid>
                                            <Grid item xs={2} textAlign="center">
                                                <Typography>{us.position}</Typography>
                                            </Grid>
                                            <Grid item xs={1.5} textAlign="center">
                                                <Typography>{us.quantity}</Typography>
                                            </Grid>
                                            <Grid item xs={1.5} textAlign="center">
                                                <Typography>{us.availableQuantity}</Typography>
                                            </Grid>
                                            <Grid item xs={1.5} display="flex" flexDirection="row" justifyContent="space-around">
                                                <ToggleButton
                                                    value="View"
                                                    title="View"
                                                    size="small"
                                                    aria-label="View"
                                                    sx={{ borderColor: "secondary.main" }}
                                                    onClick={() => getEquipmentUsers(us.name)
                                                        .then(data => {
                                                            setTempEquipmentUsers(data.users)
                                                            onPopupOpen(PopupModes.View, us.name)
                                                        })
                                                    }
                                                >
                                                    <VisibilityIcon color="secondary" />
                                                </ToggleButton>
                                                {!selectingEquipment && (
                                                    <ToggleButton
                                                        value="Edit"
                                                        title="Edit"
                                                        size="small"
                                                        aria-label="Edit"
                                                        sx={{ borderColor: "warning.main" }}
                                                        onClick={() => onPopupOpen(PopupModes.Edit, us.name)}
                                                    >
                                                        <EditIcon color="warning" />
                                                    </ToggleButton>
                                                )}
                                                {selectingEquipment && (
                                                    <ToggleButton
                                                        value="Select"
                                                        title="Select"
                                                        size="small"
                                                        aria-label="Select"
                                                        sx={{ borderColor: "success.main" }}
                                                        disabled={equipmentIsSelected(us.name) || !isEquipmentAvailable(us)}
                                                        onClick={() => onPopupOpen(PopupModes.Bind, us.name)}
                                                    >
                                                        <AddIcon color="success" />
                                                    </ToggleButton>
                                                )}
                                                {!selectingEquipment && (
                                                    <ToggleButton
                                                        value="Delete"
                                                        title="Delete"
                                                        size="small"
                                                        aria-label="Delete"
                                                        sx={{ borderColor: "error.main" }}
                                                        onClick={() => setDeleteEquipment({ id: us._id, name: us.name })}
                                                    >
                                                        <DeleteIcon color="error" />
                                                    </ToggleButton>
                                                )}
                                                {selectingEquipment && (
                                                    <ToggleButton
                                                        value="Deselect"
                                                        title="Deselect"
                                                        size="small"
                                                        aria-label="Deselect"
                                                        sx={{ borderColor: "error.main" }}
                                                        disabled={!equipmentIsSelected(us.name)}
                                                        onClick={() => onDeselectEquipment(us.name)}
                                                    >
                                                        <RemoveIcon color="error" />
                                                    </ToggleButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    />
                </Grid>
                {selectingEquipment && (
                    <Grid item mt={2} width="100%">
                        <Accordion
                            alwaysExpanded
                            title={(
                                <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}>
                                        <Typography>Currently Selected Equipment</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            subtitle={(
                                <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                    <Grid item xs={7.5}>
                                        <Typography>Name</Typography>
                                    </Grid>
                                    <Grid item xs={1.5} textAlign="center">
                                        <Typography>Available</Typography>
                                    </Grid>
                                    <Grid item xs={1.5} textAlign="center">
                                        <Typography>Quantity</Typography>
                                    </Grid>
                                    <Grid item xs={1.5} textAlign="center">
                                        <Typography>Actions</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            content={(
                                <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                    {Object.keys(selectedEquipment).map((us, ind) => (
                                        <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                            {ind !== 0
                                                && (
                                                    <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                        <Divider style={{ width: "100%" }} />
                                                    </Grid>
                                                )}
                                            <Grid key={`row_${ind}`} container item alignItems="center">
                                                <Grid item xs={7.5}>
                                                    <Typography>{us}</Typography>
                                                </Grid>
                                                <Grid item xs={1.5} textAlign="center">
                                                    <Typography>{selectedEquipment[us]?.maxQuantity}</Typography>
                                                </Grid>
                                                <Grid item xs={1.5} textAlign="center">
                                                    <Typography>{selectedEquipment[us]?.quantity}</Typography>
                                                </Grid>
                                                <Grid container item xs={1.5} textAlign="center" justifyContent="space-around">
                                                    <ToggleButton
                                                        value="View"
                                                        title="View"
                                                        size="small"
                                                        aria-label="View"
                                                        sx={{ borderColor: "secondary.main" }}
                                                        onClick={() => onPopupOpen(PopupModes.View, us)}
                                                    >
                                                        <VisibilityIcon color="secondary" />
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value="Exchange"
                                                        title="Exchange"
                                                        size="small"
                                                        aria-label="Exchange"
                                                        sx={{ borderColor: "warning.main" }}
                                                        onClick={() => onPopupOpen(PopupModes.Exchange, us)}
                                                    >
                                                        <CompareArrowsIcon color="warning" />
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value="Deselect"
                                                        title="Deselect"
                                                        size="small"
                                                        aria-label="Deselect"
                                                        sx={{ borderColor: "error.main" }}
                                                        onClick={() => onDeselectEquipment(us)}
                                                    >
                                                        <RemoveIcon color="error" />
                                                    </ToggleButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        />
                    </Grid>
                )}
            </Grid >

            <Popup
                width="600px"
                title={`${popupOpen === PopupModes.Edit ? "Edit Existing" : "Add New"} Equipment`}
                open={(popupOpen === PopupModes.Add || popupOpen === PopupModes.Edit)}
                backgroundColor="primary.main"
                onClose={() => setPopupOpen(null)}
            >
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="equipment-name"
                        label="Name"
                        variant="filled"
                        style={inputStyle}
                        value={tempEquipment.name}
                        error={tempEquipmentErrors.name !== ""}
                        helperText={tempEquipmentErrors.name}
                        onChange={(e) => onTempEquipmentChange("name", e.target.value)}
                    />
                </Grid>
                <Grid item width="100%" display="flex" flexDirection="row">
                    <Autocomplete
                        freeSolo
                        id="equipment-manufacturer"
                        options={manufacturerOptions}
                        inputValue={tempEquipment.manufacturer}
                        onInputChange={(e, newInputValue) => onTempEquipmentChange("manufacturer", newInputValue)}
                        sx={{ width: "100%" }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="filled"
                                style={inputStyle}
                                error={tempEquipmentErrors.manufacturer !== ""}
                                helperText={tempEquipmentErrors.manufacturer}
                                label="Manufacturer"
                            />
                        }
                    />
                </Grid>
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="equipment-position"
                        label="Position"
                        variant="filled"
                        style={inputStyle}
                        value={tempEquipment.position}
                        error={tempEquipmentErrors.position !== ""}
                        helperText={tempEquipmentErrors.position}
                        onChange={(e) => onTempEquipmentChange("position", e.target.value)}
                    />
                </Grid>
                <Grid item container width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                    <TextField
                        id="equipment-quantity"
                        label="Quantity"
                        variant="filled"
                        type="number"
                        style={inputStyle}
                        value={tempEquipment.quantity}
                        error={tempEquipmentErrors.quantity !== ""}
                        helperText={tempEquipmentErrors.quantity}
                        onChange={(e) => onTempEquipmentChange("quantity", Number(e.target.value))}
                    />
                </Grid>
                <Grid item container width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                    <TextField
                        id="equipment-price"
                        label="Price"
                        type="number"
                        variant="filled"
                        style={inputStyle}
                        value={tempEquipment.price}
                        error={tempEquipmentErrors.price !== ""}
                        helperText={tempEquipmentErrors.price}
                        onChange={(e) => onTempEquipmentChange("price", e.target.value)}
                    />
                </Grid>
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="equipment-lend-quantity"
                        label="Equipment Description"
                        multiline
                        minRows={2}
                        maxRows={3}
                        variant="filled"
                        style={inputStyle}
                        value={tempEquipment.description}
                        onChange={(e) => onTempEquipmentChange("description", e.target.value)}
                    />
                </Grid>
                <Grid container justifyContent="flex-start">
                    <PrimaryBackgroundButton
                        width={"100%"}
                        title={popupOpen === PopupModes.Edit ? "Edit" : "Add"}
                        onClick={() => onEquipmentChange()}
                    />
                </Grid>
            </Popup>

            <Popup
                width="800px"
                title={"View Equipment"}
                open={popupOpen === PopupModes.View}
                backgroundColor="primary.main"
                onClose={() => setPopupOpen(null)}
            >
                <Grid item width="100%">
                    <Accordion
                        alwaysExpanded
                        useShadow={false}
                        title={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Typography>Equipment Information</Typography>
                            </Grid>
                        )}
                        subtitle={(
                            <Grid container width="100%" height="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item width="100%" display="flex" flexDirection="row" alignItems="center" padding="3px">
                                    <Grid item xs={1} />
                                    <Grid item xs={5}>
                                        <Typography>Name</Typography>
                                    </Grid>
                                    <Grid item xs={5} textAlign="end">
                                        <Typography>{tempEquipment.name}</Typography>
                                    </Grid>
                                    <Grid item xs={1} />
                                </Grid>
                                <Grid item width="100%" display="flex" flexDirection="row" alignItems="center" padding="3px">
                                    <Grid item xs={1} />
                                    <Grid item xs={5}>
                                        <Typography>Manufacturer</Typography>
                                    </Grid>
                                    <Grid item xs={5} textAlign="end">
                                        <Typography>{tempEquipment.manufacturer}</Typography>
                                    </Grid>
                                    <Grid item xs={1} />
                                </Grid>
                                <Grid item width="100%" display="flex" flexDirection="row" alignItems="center" padding="3px">
                                    <Grid item xs={1} />
                                    <Grid item xs={5}>
                                        <Typography>Position</Typography>
                                    </Grid>
                                    <Grid item xs={5} textAlign="end">
                                        <Typography>{tempEquipment.position}</Typography>
                                    </Grid>
                                    <Grid item xs={1} />
                                </Grid>
                                <Grid item width="100%" display="flex" flexDirection="row" alignItems="center" padding="3px">
                                    <Grid item xs={1} />
                                    <Grid item xs={5}>
                                        <Typography>Quantity</Typography>
                                    </Grid>
                                    <Grid item xs={5} textAlign="end">
                                        <Typography>{tempEquipment.quantity}</Typography>
                                    </Grid>
                                    <Grid item xs={1} />
                                </Grid>
                                <Grid item width="100%" display="flex" flexDirection="row" alignItems="center" padding="3px">
                                    <Grid item xs={1} />
                                    <Grid item xs={5}>
                                        <Typography>Available</Typography>
                                    </Grid>
                                    <Grid item xs={5} textAlign="end">
                                        <Typography>{tempEquipment.availableQuantity}</Typography>
                                    </Grid>
                                    <Grid item xs={1} />
                                </Grid>
                                <Grid item width="100%" display="flex" flexDirection="row" alignItems="center" padding="3px">
                                    <Grid item xs={1} />
                                    <Grid item xs={5}>
                                        <Typography>Price</Typography>
                                    </Grid>
                                    <Grid item xs={5} textAlign="end">
                                        <Typography>{`${tempEquipment.price} (€)`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} />
                                </Grid>
                            </Grid>
                        )}
                    />
                    <Accordion
                        alwaysExpanded
                        useShadow={false}
                        title={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Typography>Equipment Description</Typography>
                            </Grid>
                        )}
                        subtitle={(
                            <Grid item textAlign="start" spacing={0}>
                                <Typography>{tempEquipment.description !== "" ? tempEquipment.description : "No description found."}</Typography>
                            </Grid>
                        )}
                    />
                    <Accordion
                        alwaysExpanded
                        title={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Typography>Equipment Lent Users</Typography>
                            </Grid>
                        )}
                        subtitle={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={1} />
                                <Grid item xs={6}>
                                    <Typography>Name</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Quantity</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="end">
                                    <Typography>Action</Typography>
                                </Grid>
                                <Grid item xs={1} />
                            </Grid>
                        )}
                        content={(
                            <Grid container width="100%" height="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                {tempEquipmentUsers.map((u, ind) => (
                                    <Grid item key={`comp_${ind}`} width="100%" display="flex" flexDirection="row" alignItems="center" padding="3px">
                                        <Grid item xs={1} />
                                        <Grid item xs={6}>
                                            <Typography>{u.name}</Typography>
                                        </Grid>
                                        <Grid item xs={2} textAlign="center">
                                            <Typography>{u.quantity}</Typography>
                                        </Grid>
                                        <Grid item xs={2} textAlign="end">
                                            <ToggleButton
                                                value="Inspect"
                                                title="Inspect"
                                                size="small"
                                                aria-label="Inspect"
                                                sx={{ borderColor: "secondary.main" }}
                                                onClick={() => onInspectUser(u.name)}
                                            >
                                                <OpenInNewIcon color="secondary" />
                                            </ToggleButton>
                                        </Grid>
                                        <Grid item xs={1} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    />
                    <Grid item xs={12} padding="10px" />
                    <Grid container justifyContent="flex-start" >
                        <PrimaryBackgroundButton
                            width={"100%"}
                            title={"Close"}
                            onClick={() => onPopupOpen(PopupModes.Close)}
                        />
                    </Grid>
                </Grid>
            </Popup>

            <Popup
                width="800px"
                title={(Object.keys(selectedEquipment).length === 0) ? "Lend Equipment" : "Lending Overview"}
                open={popupOpen === PopupModes.Lend}
                backgroundColor="primary.main"
                onClose={() => setPopupOpen(PopupModes.Close)}
            >
                <Grid item width="100%" display="flex" flexDirection="row">
                    <Autocomplete
                        freeSolo
                        id="equipment-lend-full-name"
                        options={userOptions}
                        onChange={onAutocompleteName}
                        inputValue={tempLendEquipment.fullName}
                        onInputChange={(e, newInputValue) => onTempLendEquipmentChange("fullName", newInputValue)}
                        sx={{ width: "100%" }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="filled"
                                style={inputStyle}
                                error={tempLendEquipmentErrors.fullName !== ""}
                                helperText={tempLendEquipmentErrors.fullName}
                                label="Full Name"
                            />
                        }
                    />
                </Grid>
                <Grid item width="100%" display="flex" flexDirection="row">
                    <Autocomplete
                        freeSolo
                        id="equipment-lend-phone"
                        options={phoneOptions}
                        onChange={onAutocompletePhone}
                        inputValue={tempLendEquipment.phone}
                        onInputChange={(e, newInputValue) => onTempLendEquipmentChange("phone", newInputValue)}
                        sx={{ width: "100%" }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="filled"
                                style={inputStyle}
                                error={tempLendEquipmentErrors.phone !== ""}
                                helperText={tempLendEquipmentErrors.phone}
                                label="Phone"
                            />
                        }
                    />
                </Grid>
                <Grid container item width="100%" display="flex" flexDirection="row" justifyContent="space-between">
                    <Grid item xs={4.5}>
                        <TextField
                            disabled
                            id="equipment-lend-at"
                            label="Lend At"
                            variant="filled"
                            style={inputStyle}
                            value={dayjs(tempLendEquipment.lentAt).format('DD/MM/YYYY')}
                            onChange={(e) => onTempLendEquipmentChange("lentAt", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            id="equipment-lend-months"
                            label="Months"
                            type="number"
                            variant="filled"
                            style={inputStyle}
                            value={tempLendEquipment.duration}
                            error={tempLendEquipmentErrors.duration !== ""}
                            helperText={tempLendEquipmentErrors.duration}
                            onChange={(e) => onTempLendEquipmentChange("duration", e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4.5}>
                        <TextField
                            disabled
                            id="equipment-lend-return-at"
                            label="Return At"
                            variant="filled"
                            style={inputStyle}
                            value={dayjs(tempLendEquipment.returnAt).format('DD/MM/YYYY')}
                            onChange={(e) => onTempLendEquipmentChange("lentAt", e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="equipment-lend-quantity"
                        label="Description / Cause"
                        multiline
                        minRows={2}
                        maxRows={3}
                        variant="filled"
                        style={inputStyle}
                        value={tempLendEquipment.description}
                        error={tempLendEquipmentErrors.description !== ""}
                        helperText={tempLendEquipmentErrors.description}
                        onChange={(e) => onTempLendEquipmentChange("description", e.target.value)}
                    />
                </Grid>
                <Grid container justifyContent="space-between">
                    {(Object.keys(selectedEquipment).length === 0) && (
                        <PrimaryBackgroundButton
                            width={"100%"}
                            title={"Find Equipment"}
                            onClick={() => onStartSelectEquipment()}
                        />
                    )}
                    {(Object.keys(selectedEquipment).length !== 0) && (
                        <>
                            <SecondaryBorderButton
                                width={"47.5%"}
                                title={"Continue Lending"}
                                onClick={() => onStartSelectEquipment()}
                            />
                            <PrimaryBackgroundButton
                                width={"47.5%"}
                                title={"Lend Equipment"}
                                onClick={() => onLendEquipment()}
                            />
                        </>
                    )}
                </Grid>
            </Popup>

            <Popup
                width="600px"
                title={popupOpen === PopupModes.Bind ? "Select Equipment" : "Exchange Equipment"}
                open={popupOpen === PopupModes.Bind || popupOpen === PopupModes.Exchange}
                backgroundColor="primary.main"
                onClose={() => setPopupOpen(PopupModes.Close)}
            >
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="equipment-to-bind-exchange-name"
                        label="Equipment Name"
                        variant="filled"
                        style={inputStyle}
                        value={tempBindEquipment.name}
                        disabled
                    />
                </Grid>
                <Grid container item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignContent="center">
                    <Grid item xs={5}>
                        <TextField
                            id="equipment-to-bind-exchange-quatity"
                            label="Selected"
                            variant="filled"
                            type="number"
                            style={inputStyle}
                            value={tempBindEquipment.quantity}
                            onChange={(e) => onExchangeEquipment(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <ToggleButton
                            value="Exchange"
                            title="Exchange"
                            size="small"
                            aria-label="Exchange"
                            sx={{ borderColor: "secondary.main", marginTop: "6px" }}
                            disabled
                        >
                            <CompareArrowsIcon color="secondary" />
                        </ToggleButton>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id="equipment-to-exhange-binded"
                            label="Available"
                            variant="filled"
                            style={inputStyle}
                            type="number"
                            value={tempBindEquipment.maxQuantity}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <SecondaryBorderButton
                        width="47.5%"
                        title={"Cancel"}
                        onClick={() => setPopupOpen(PopupModes.Close)}
                    />
                    <PrimaryBackgroundButton
                        width="47.5%"
                        title={popupOpen === PopupModes.Bind ? "Select" : "Exchange"}
                        onClick={() => onBindEquipment()}
                    />
                </Grid>
            </Popup>

            <Dialog
                open={deleteEquipment?.id ? true : false}
                onClose={() => setDeleteEquipment({ id: null, username: null })}
            >
                <DialogTitle>
                    {"Delete Equipment?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Are you sure you want to delete the equipment ${deleteEquipment?.name}?`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryBorderButton onClick={() => setDeleteEquipment({ id: null, name: null })} title="Cancel" />
                    <SecondaryBackgroundButton onClick={onDelete} title="Delete" />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default memo(LabEquipment);
