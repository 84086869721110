import { Grid, Typography, Divider, Autocomplete, ToggleButton, Pagination, Dialog, TextField, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from '@mui/icons-material/Check';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SaveIcon from '@mui/icons-material/Save';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { memo, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"

import Accordion from "../../components/Accordion";
import { SecondaryBackgroundButton, PrimaryBorderButton, SecondaryBorderButton, PrimaryBackgroundButton } from "../../components/Buttons";
import Popup from "../../components/Popup";
import Search from "../../components/Search";
import Spinner from "../../components/Spinner";
import { isFuzzyMatch, useSnackbar, dayjs } from "../../utils";
import { getLabUsers, getUserGroupEquipment, updateSelectedGroup, deleteSelectedGroup } from "../../api";

const inputStyle = {
    color: "black",
    width: "100%",
    backgroundColor: "white",
    opacity: 0.7,
    borderRadius: "4px",
    margin: "0px",
    marginBottom: "10px",
};

const LabUsers = () => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const PopupModes = {
        View: "view",
        Close: "close",
        Exchange: "Exchange",
        Return: "Return",
    };

    const { error, success } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [searchFilter, setSearchFilter] = useState("");

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredEquipment] = useState(users);
    const [popupOpen, setPopupOpen] = useState(PopupModes.Close);
    const [selectedUser, setSelectedUser] = useState({});
    const [selectedGroup, setSelectedGroup] = useState({});

    const [tempBindEquipment, setTempBindEquipment] = useState({
        name: "",
        quantity: "",
        maxQuantity: "",
    });

    const calculatedTotalItems = (user) => {
        let totalItems = 0;

        user.groups.forEach(group => {
            totalItems += Object.keys(group.refEquipment).reduce((acc, curr) => (acc + group.refEquipment[curr]), 0);
        });

        return totalItems;
    };

    const calculatedTotalGroups = (user) => {
        return user.groups.length;
    };

    const isUserInDebt = (user) => {
        const today = new Date();

        for (const g of user.groups) {
            const returnAt = new Date(g.returnAt);

            if (returnAt < today) {
                return true;
            }
        }

        return false;
    };

    const returnAt = (user) => {
        if (user.groups.length > 0) {
            let lastDate = new Date(user.groups[0].returnAt);

            for (const group of user.groups) {
                const currentDate = new Date(group.returnAt);

                if (currentDate.getTime() > lastDate.getTime()) {
                    lastDate = currentDate;
                }
            }

            return lastDate;
        }
    };

    const fetchData = useCallback(
        async () => {
            setIsLoading(true);

            try {
                const { success, users: u } = await getLabUsers();
                if (success) {
                    const sortedU = u.sort((a, b) => (b.groups.length - a.groups.length));
                    setUsers(sortedU);
                } else {
                    error();
                }
            } catch (_error) {
                error();
            }

            setIsLoading(false);
        }, []
    );

    const onLoadGroupEquipment = useCallback(
        async (user, groupIndex = undefined) => {
            setIsLoading(true);

            try {
                const { success, group: g } = await getUserGroupEquipment(user.groups[groupIndex ? groupIndex : 0]._id);

                if (success) {
                    setSelectedGroup(g);
                } else {
                    error();
                }
            } catch (_error) {
                error();
            }

            setIsLoading(false);
        }, []
    );

    const onPopupOpen = async (mode, data) => {
        switch (mode) {
            case PopupModes.View:
                setSelectedUser(data);
                onLoadGroupEquipment(data);
                break;
            case PopupModes.Exchange:
                setTempBindEquipment({
                    name: data.name,
                    quantity: data.quantity,
                    maxQuantity: data.available,
                });
                break;
            case PopupModes.Return:
                onReturnEquipment({
                    name: data.name,
                    quantity: data.quantity,
                    maxQuantity: data.available,
                });
                break;
            default:
                break;
        };

        setPopupOpen(mode);
    };

    const onExchangeEquipment = (quantity) => {
        let newQuantity = parseInt(quantity);

        if (newQuantity === NaN) {
            return;
        }

        const totalEquipment = tempBindEquipment.quantity + tempBindEquipment.maxQuantity;

        if (quantity <= 0) {
            newQuantity = 1;
        }

        if (quantity > totalEquipment) {
            newQuantity = totalEquipment;
        }

        setTempBindEquipment({
            ...tempBindEquipment,
            quantity: newQuantity,
            maxQuantity: (totalEquipment - newQuantity),
        });
    };

    const onBindEquipment = () => {
        const equipmentIndex = selectedGroup.equipment.findIndex((e) => e.name === tempBindEquipment.name);
        const updatedEquipment = selectedGroup.equipment;

        updatedEquipment[equipmentIndex].quantity = tempBindEquipment.quantity;
        updatedEquipment[equipmentIndex].available = tempBindEquipment.maxQuantity;

        setSelectedGroup({
            ...selectedGroup,
            equipment: updatedEquipment
        });

        setPopupOpen(PopupModes.View);
    };

    const onReturnEquipment = (tempEquipment) => {
        const equipmentIndex = selectedGroup.equipment.findIndex((e) => e.name === tempEquipment.name);
        const updatedEquipment = selectedGroup.equipment;

        updatedEquipment[equipmentIndex].available += updatedEquipment[equipmentIndex].quantity;
        updatedEquipment[equipmentIndex].quantity = 0;

        setSelectedGroup({
            ...selectedGroup,
            equipment: updatedEquipment
        });

        setPopupOpen(PopupModes.View);
    };

    const onReturnGroupEquipment = async () => {
        setIsLoading(true);

        try {
            const { success } = await deleteSelectedGroup(selectedGroup);

            if (success) {
                fetchData();
            } else {
                error();
            }
        } catch (_error) {
            error();
        }

        setPopupOpen(PopupModes.Close);

        setIsLoading(false);
    };

    const onSaveGroupEquipment = async () => {
        setIsLoading(true);

        try {
            const { success } = await updateSelectedGroup(selectedGroup);

            if (success) {
                fetchData();
            } else {
                error();
            }
        } catch (_error) {
            error();
        }

        setPopupOpen(PopupModes.Close);

        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [fetchData]);

    useEffect(() => {
        setFilteredEquipment(users.filter((eq) =>
            isFuzzyMatch(eq.name, searchFilter)
            || isFuzzyMatch(eq.manufacturer, searchFilter)
            || isFuzzyMatch(eq.lastUpdatedAt, searchFilter)
        ));

    }, [searchFilter, users]);

    useEffect(() => {
        const userName64 = params.get("userId");

        if (userName64 !== null) {
            onPopupOpen(PopupModes.View, { fullName: atob(userName64) });
        }
    }, [params]);
    return (
        <>
            <Spinner open={isLoading} />
            <Grid
                container
                display="flex"
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    container
                    item
                    width="100%"
                    mt={2}
                    p={1}
                    pr={2}
                    mb={2}
                    display="flex"
                    minHeight="60px"
                    borderRadius={"20px"}
                    alignItems={"center"}
                    sx={{
                        background: "linear-gradient(to left, #04598c 0%, #193256 100%)",
                        boxShadow: "10px 3px 10px -5px #262835",
                    }}
                >
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                        justifyContent="center"
                        sx={{
                            borderRadius: "30px 0px 30px 0px",
                            borderTopLeftRadius: "30px",
                            boxShadow: "2px 2px 14px #262835",
                            background: "linear-gradient(to right, #04598c 0%, #193256 150%)"
                        }}
                    >
                        <SettingsSuggestIcon fontSize="large" color="white" />
                    </Grid>
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                    >
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Search value={searchFilter} placeholder="Search by Name" onChange={(event) => setSearchFilter(event.target.value)} />
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                    >
                    </Grid>
                </Grid>
            </Grid >
            <Grid item mt={2} width="100%">
                <Accordion
                    alwaysExpanded
                    titleBackground="secondary"
                    title={(
                        <Grid
                            container
                            display="flex"
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={4} textAlign="start">
                                <Typography>Users</Typography>
                            </Grid>
                            <Grid item xs={8} />
                        </Grid>
                    )}
                    subtitle={(
                        <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                            <Grid item xs={3.5} textAlign="start">
                                <Typography>Name</Typography>
                            </Grid>
                            <Grid item xs={1.5} textAlign="center">
                                <Typography>Phone</Typography>
                            </Grid>
                            <Grid item xs={1.5} textAlign="center">
                                <Typography>Lendings</Typography>
                            </Grid>
                            <Grid item xs={1.5} textAlign="center">
                                <Typography>Items</Typography>
                            </Grid>
                            <Grid item xs={1.5} textAlign="center">
                                <Typography>Return At</Typography>
                            </Grid>
                            <Grid item xs={1.5} textAlign="center">
                                <Typography>In debt</Typography>
                            </Grid>
                            <Grid item xs={1} textAlign="center">
                                <Typography>Actions</Typography>
                            </Grid>
                        </Grid>
                    )}
                    content={(
                        <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                            {filteredUsers.map((e, ind) => (
                                <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                    {
                                        ind !== 0
                                        && (
                                            <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                <Divider style={{ width: "100%" }} />
                                            </Grid>
                                        )
                                    }
                                    <Grid key={`row_${ind}`} container item alignItems="center">
                                        <Grid item xs={3.5} textAlign="start">
                                            <Typography>{e.fullName}</Typography>
                                        </Grid>
                                        <Grid item xs={1.5} textAlign="center">
                                            <Typography>{e.phone}</Typography>
                                        </Grid>
                                        <Grid item xs={1.5} textAlign="center">
                                            <Typography>{calculatedTotalGroups(e)}</Typography>
                                        </Grid>
                                        <Grid item xs={1.5} textAlign="center">
                                            <Typography>{calculatedTotalItems(e)}</Typography>
                                        </Grid>
                                        <Grid item xs={1.5} textAlign="center">
                                            <Typography>{dayjs(returnAt(e)).format('DD/MM/YYYY')}</Typography>
                                        </Grid>
                                        <Grid item xs={1.5} textAlign="center">
                                            <Typography>{isUserInDebt(e) ? "Yes" : "No"}</Typography>
                                        </Grid>
                                        <Grid item xs={1} textAlign="center">
                                            <Grid item container display="flex" flexDirection="row" justifyContent="space-around">
                                                <ToggleButton
                                                    value="View"
                                                    title="View"
                                                    size="small"
                                                    aria-label="View"
                                                    sx={{ borderColor: "secondary.main" }}
                                                    disabled={e.groups.length === 0}
                                                    onClick={() => onPopupOpen(PopupModes.View, e)}
                                                >
                                                    <VisibilityIcon color="secondary" />
                                                </ToggleButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                />
            </Grid >

            <Popup
                width="800px"
                title="View Lending Groups"
                open={popupOpen !== PopupModes.Close}
                backgroundColor="primary.main"
                onClose={() => setPopupOpen(PopupModes.Close)}
            >
                <Grid item container width="100%" justifyContent="center">
                    <Pagination
                        count={selectedUser?.groups?.length}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                            "& .MuiPaginationItem-root": {
                                borderColor: "primary.main",
                            },
                            ".Mui-selected": {
                                color: "#fff",
                                backgroundColor: "primary.main",
                            }
                        }}
                        onChange={(e, v) => onLoadGroupEquipment(selectedUser, (v - 1))}
                    />


                </Grid>
                <Grid item mt={3} width="100%" display="flex" flexDirection="row">
                    <Accordion
                        alwaysExpanded
                        titleBackground="secondary"
                        title={(
                            <Grid
                                container
                                display="flex"
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={2} textAlign="start">
                                    <Typography><b>{`Group [${0}]`}</b></Typography>
                                </Grid>
                                <Grid item xs={3.5} textAlign="center">
                                    <Typography>
                                        <b>Lend At:</b>
                                        &nbsp;
                                        <b>{dayjs(new Date(selectedGroup.lentAt)).format('DD/MM/YYYY')}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3.5} textAlign="center">
                                    <Typography>
                                        <b>Return At:</b>
                                        &nbsp;
                                        <b>{dayjs(new Date(selectedGroup.returnAt)).format('DD/MM/YYYY')}</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item container xs={2} textAlign="center" justifyContent="space-around">
                                    <ToggleButton
                                        value="Save Group's Equipment"
                                        title="Save Group's Equipment"
                                        size="small"
                                        aria-label="Save Group's Equipment"
                                        sx={{ backgroundColor: "primary.main" }}
                                        onClick={() => onSaveGroupEquipment()}
                                    >
                                        <SaveIcon color="secondary" />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="Return Group's Equipment"
                                        title="Return Group's Equipment"
                                        size="small"
                                        aria-label="Return Group's Equipment"
                                        sx={{ backgroundColor: "success.main" }}
                                        onClick={() => onReturnGroupEquipment()}
                                    >
                                        <CheckIcon color="secondary" />
                                    </ToggleButton>
                                </Grid>
                            </Grid>
                        )}
                        subtitle={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={3.5} textAlign="start">
                                    <Typography>Name</Typography>
                                </Grid>
                                <Grid item xs={2.5} textAlign="center">
                                    <Typography>Position</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Quantity</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Available</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Action</Typography>
                                </Grid>
                            </Grid>
                        )}
                        content={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                {Object.keys(selectedGroup).length > 0 &&
                                    selectedGroup?.equipment.map((e, ind) => (
                                        <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                            {
                                                ind !== 0
                                                && (
                                                    <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                        <Divider style={{ width: "100%" }} />
                                                    </Grid>
                                                )
                                            }
                                            <Grid key={`row_${ind}`} container item alignItems="center">
                                                <Grid item xs={3.5} textAlign="start">
                                                    <Typography>{e.name}</Typography>
                                                </Grid>
                                                <Grid item xs={2.5} textAlign="center">
                                                    <Typography>{e.position}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography>{e.quantity}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography>{e.available}</Typography>
                                                </Grid>
                                                <Grid container item xs={2} textAlign="center" justifyContent="space-around">
                                                    <ToggleButton
                                                        value="Exchange"
                                                        title="Exchange"
                                                        size="small"
                                                        aria-label="Exchange"
                                                        sx={{ borderColor: "warning.main" }}
                                                        onClick={() => onPopupOpen(PopupModes.Exchange, e)}
                                                    >
                                                        <CompareArrowsIcon color="warning" />
                                                    </ToggleButton>
                                                    <ToggleButton
                                                        value="Return"
                                                        title="Return"
                                                        size="small"
                                                        aria-label="Return"
                                                        sx={{ borderColor: "success.main" }}
                                                        onClick={() => onPopupOpen(PopupModes.Return, e)}
                                                    >
                                                        <CheckIcon color="success" />
                                                    </ToggleButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Grid>
                        )}
                    />
                </Grid>
            </Popup >


            <Popup
                width="600px"
                title={"Exchange Equipment"}
                open={popupOpen === PopupModes.Exchange}
                backgroundColor="primary.main"
                onClose={() => setPopupOpen(PopupModes.View)}
            >
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="equipment-to-bind-exchange-name"
                        label="Equipment Name"
                        variant="filled"
                        style={inputStyle}
                        value={tempBindEquipment.name}
                        disabled
                    />
                </Grid>
                <Grid container item width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignContent="center">
                    <Grid item xs={5}>
                        <TextField
                            id="equipment-to-bind-exchange-quatity"
                            label="Selected"
                            variant="filled"
                            type="number"
                            style={inputStyle}
                            value={tempBindEquipment.quantity}
                            onChange={(e) => onExchangeEquipment(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <ToggleButton
                            value="Exchange"
                            title="Exchange"
                            size="small"
                            aria-label="Exchange"
                            sx={{ borderColor: "secondary.main", marginTop: "6px" }}
                            disabled
                        >
                            <CompareArrowsIcon color="secondary" />
                        </ToggleButton>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id="equipment-to-exhange-binded"
                            label="Available"
                            variant="filled"
                            style={inputStyle}
                            type="number"
                            value={tempBindEquipment.maxQuantity}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <SecondaryBorderButton
                        width="47.5%"
                        title={"Cancel"}
                        onClick={() => setPopupOpen(PopupModes.View)}
                    />
                    <PrimaryBackgroundButton
                        width="47.5%"
                        title={"Exchange"}
                        onClick={() => onBindEquipment()}
                    />
                </Grid>
            </Popup>
        </>
    );
};

export default memo(LabUsers);
