import { memo, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Formik } from 'formik';

import Input from "./Input";
import { validations } from "../utils/index";
import { PrimaryBackgroundButton } from "./Buttons";
import Dropdown from "./Dropdown";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
	form: {
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center"
	},
	input: {
		color: "black",
		width: "100%",
		maxWidth: "300px",
		backgroundColor: "white",
		opacity: 0.7,
		borderRadius: "4px",
        marginBottom: "10px",
		"&:hover": {
			opacity: 0.8
		},
	},
	dropdown: {
		width: "100%",
		maxWidth: "300px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "white"
	},
	buttonTitle: {
		color: "white",
		letterSpacing: theme.spacing(0.1),
	},
	button: {
		width: "100%",
		maxWidth: "300px",
	}
}));

const Form = ({ disabled: dsb, content, validationSchema, onSubmit, onSubmitProps, toResetForm = true }) => {
	const classes = useStyles();
    const [formContent, setFormContent] = useState(content);
    const [disabled, setDisabled] = useState(dsb);

    useEffect(() => {
        setFormContent(content);
    }, [content]);

    useEffect(() => {
        setDisabled(dsb);
    }, [dsb]);

	return (
        <Formik
            initialValues={formContent.reduce((a,v) => (
                v.customType === "input"
                ? {...a, [v.id]: v.value || ""}
                : (v.customType === "dropdown"
                    ? { ...a, [v.id]: v.defaultValue }
                    : a
                )
            ), {})}
            validationSchema={validations?.[validationSchema] || null}
            validateOnChange={false}
            onSubmit={(...formikArgs) => {
                onSubmit(...formikArgs, onSubmitProps);
                const [, { resetForm, setSubmitting }] = formikArgs;
                if (toResetForm) resetForm();
                setSubmitting(false);
            }}
        >
            {formikProps => (
                <form className={classes.form} onSubmit={formikProps.handleSubmit}>
                    {formContent.map((comp) => (
                        <div
                            key={comp.id}
                            style={{ width: "100%", display: "flex", justifyContent: "center" }}
                        >
                            {comp.customType === "input"
                            && (
                                <Input
                                    key={comp.id}
                                    id={comp.id}
                                    type={comp.type}
                                    className={classes.input}
                                    placeholder={comp.placeholder}
                                    variant="filled"
                                    color="secondary"
                                    InputProps={comp.inputProps}
                                    value={formikProps.values[comp.id]}
                                    onChange={formikProps.handleChange}
                                    error={Boolean(formikProps.errors[comp.id])}
                                    helperText={formikProps.errors[comp.id]}
                                    disabled={disabled || comp.disabled}
                                />
                            )}
                            {comp.customType === "dropdown"
                            && (
                                <Grid item className={classes.dropdown}>
                                    <Typography>{comp.label}</Typography>
                                    <Dropdown
                                        id={comp.id}
                                        items={comp.items}
                                        value={formikProps.values[comp.id]}
                                        onChange={(event) => formikProps.handleChange({
                                            target: {
                                                name: comp.id,
                                                value: event.target.value,
                                            },
                                        })}
                                        disabled={disabled}
                                        size="medium"
                                        width="200px"
                                        filled={false}
                                        background="secondary"
                                    />
                                </Grid>
                            )}
                            {comp.customType === "button"
                            && (
                                <PrimaryBackgroundButton
                                    id={comp.id}
                                    type={comp.type}
                                    disabled={formikProps.isSubmitting || disabled}
                                    className={classes.button}
                                    size="large"
                                    width="100%"
                                    title={comp.text}
                                />
                            )}
                        </div>
                    ))}
                </form>
            )}
        </Formik>
	);
};

export default memo(Form);
