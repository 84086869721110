import ky from "ky";
import queryString from "query-string";

import { jwt } from "../utils";

const { REACT_APP_MAIN_SERVER_URL } = process.env;

const rootApi = ky.extend({
	timeout: false,
	prefixUrl: `${REACT_APP_MAIN_SERVER_URL}/api`,
	retry: {
		statusCodes: [401, 408, 413, 429, 502, 503, 504],
		limit: 2,
		methods: ["get", "post", "put", "head", "delete", "options", "trace"],
	},
	hooks: {
		beforeRequest: [({ headers }) => {
			headers.set("x-access-token", jwt.getToken());
		}],
		beforeRetry: [
			async ({ request: { method }, error }) => {
				if (error?.response?.status === 401) {
					const res = await rootApi.extend({ throwHttpErrors: false, retry: 0 }).get("api/refresh");
					if (res.status === 401) {
						jwt.destroyToken();
						window.location.href = "/";
					} else {
						const { token } = await res.json();
						jwt.setToken(token);
					}
				} else if (method === "POST") {
					throw error;
				}
			},
		],
		afterResponse: [
			(_req, _opts, res) => {
				const { status } = res;
				if (status === 500) {
					return new Response(JSON.stringify({ success: false }), { status: 200 });
				}

				if (status === 404) {
					window.location.href = "/";
				}

				return res;
			},
		],
	},
});

const api = {
	get: (path, searchParams) => rootApi.get(path, { searchParams: queryString.stringify(searchParams) }).json(),
	post: (path, json) => rootApi.post(path, { json }).json(),
	put: (path, json) => rootApi.put(path, { json }).json(),
	patch: (path, json) => rootApi.patch(path, { json }).json(),
	delete: (path, json) => rootApi.delete(path, { json }).json(),
};

export default api;

export const authenticate = (username, password) => api.post("authenticate", { username, password });
export const forgotPassword = (username) => api.post("forgotPassword", { username });
export const resetPassword = (password, token) => api.post("resetPassword", { password, token });
export const signUp = (username, email, password, token) => api.post("createUser", { username, email, password, token });
export const uploadFile = (body) => rootApi.post("file/", { body }).json();
export const reUploadFile = (body) => rootApi.put("file/", { body }).json();
export const deleteFile = (info) => api.post("file/delete/", info);
export const getInspectionData = (timeFilter) => api.get("inspection", { timeFilter });
export const getInspectionComponentData = (timeFilter, category, name) => api.get("inspection/component", { timeFilter, category, name });
export const getUsersData = () => api.get("user");
export const removeChecksUser = (id) => api.post("user/delete", { id });
export const inviteUser = (email) => api.post("user", { email });
export const getServicesUsersData = () => api.get("services/users");
export const deactivateUser = (id) => api.post("services/users/deactivate", { id });
export const activateUser = (id) => api.post("services/users/activate", { id });
export const removeUser = (id) => api.post("services/users/delete", { id });
export const newUser = (data) => api.post("services/users/", { data });
export const editUser = (data) => api.put("services/users/", { data });
export const getServicesHackingData = (timeFilter) => api.get("services/hacking", { timeFilter });
export const getServicesServicesData = (timeFilter, excludeTests) => api.get("services/services", { timeFilter, excludeTests });
export const getServicesCallData = (timeFilter, excludeTests, call, group) => api.get("services/services/call", { timeFilter, excludeTests, call, group });
export const getR4alzStats = () => api.get("r4alz/stats");
export const getR4alzSubmissions = () => api.get("r4alz/stats/submissions");
export const exportR4alzSubmissions = (ex, email) => api.get("r4alz/stats/export", { ex, email });
export const executeChecks = () => api.post("inspection/check/", {});
export const getActiveChecks = () => api.get("inspection/active_check/", {});
export const getPm2Checks = () => api.get("inspection/pm2/");
export const getEquipmentData = () => api.get("lab/equipment/");
export const getEquipmentUsers = (name) => api.get("lab/equipment/users", { name });
export const addNewEquipment = (equipment) => api.post("lab/equipment/", { equipment });
export const editEquipment = (equipment) => api.put("lab/equipment/", { equipment });
export const removeEquipment = (id) => api.delete("lab/equipment/", { id });
export const lendEquipment = (data) => api.post("lab/equipment/lend", { data });
export const updateSelectedGroup = (data) => api.put("lab/equipment/group", { data });
export const deleteSelectedGroup = (data) => api.delete("lab/equipment/group", { data });

export const getLabUsers = () => api.get("lab/users/");
export const getUserGroupEquipment = (id) => api.get("lab/users/group/equipment", { id });
