import { makeStyles } from "@mui/styles";
import Image from "mui-image";
import { SecondaryBackgroundButton } from "../components/Buttons";

import logo from "../assets/images/oops.png";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		height: "100%",
		overflow: "auto",
		padding: "3rem 1.5rem",
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
}));

const NotFound = () => {
	const classes = useStyles();
	return (
		<div className={classes.main}>
				<div style={{ width: "100%", maxWidth: "350px", marginBottom: "-20px" }}>
					<Image src={logo} alt="Oops" fit="contain" sx={{ width: "300px", height: "auto" }} />
				</div>
				<SecondaryBackgroundButton title="Abort Abort!"  onClick={() => { window.location.href = "/"; }} />
		</div>
	);
};

export default NotFound;
