import { Grid, Typography, Divider } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import Accordion from "../../components/Accordion";
import Search from "../../components/Search";
import Spinner from "../../components/Spinner";
import Dropdown from "../../components/Dropdown";
import { isFuzzyMatch, useSnackbar, dayjs } from "../../utils";
import { getServicesHackingData } from "../../api";

const useStyles = makeStyles(() => ({
    statistics: {
        maxWidth: "500px",
        background: "rgba(255, 255, 255, 1)",
        borderRadius: "10px",
        padding: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
}));

const timeOptions = [
    { text: "last day", value: "last day" },
    { text: "last week", value: "last week" },
    { text: "last 2 weeks", value: "last 2 weeks" },
    { text: "last month", value: "last month" },
    { text: "last 3 months", value: "last 3 months" },
    { text: "last 6 months", value: "last 6 months" },
    { text: "all", value: "all" },
];

const Hacking = () => {
    const classes = useStyles();
    const { error } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [timeFilter, setTimeFilter] = useState("last week");
    const [searchFilter, setSearchFilter] = useState("");
    const [attempts, setAttempts] = useState([]);
    const [groupedAttempts, setGroupedAttempts] = useState([]);
    const [filteredAttempts, setFilteredAttempts] = useState(attempts);

    const fetchData = useCallback(
        async () => {
            setIsLoading(true);

            try {
                const { success: successCode, attempts: atts } = await getServicesHackingData(timeFilter);
                if (successCode) {
                    setAttempts(atts);
                    // Parse by url
                    let urls = {}
                    for (let i in atts) {
                        if (!(atts[i].url in urls)) {
                            urls[atts[i].url] = [atts[i].headers];
                        }
                        else {
                            urls[atts[i].url].push(atts[i].headers)
                        }
                    }
                    let sortable = [];
                    for (var u in urls) {
                        sortable.push([u, urls[u].length, urls[u][0]]);
                    }
                    sortable.sort(function(a, b) {
                        return b[1] - a[1];
                    });
                    setGroupedAttempts(sortable);
                } else {
                    error();
                }
            } catch (_error) {
                error();
            }

            setIsLoading(false);
        },
        [timeFilter]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [fetchData]);

    useEffect(() => {
        setFilteredAttempts(attempts.filter((att) =>
            isFuzzyMatch(att.url, searchFilter)
            || isFuzzyMatch(att.headers, searchFilter)
            || isFuzzyMatch(att.timestamp, searchFilter)
        ));
    }, [searchFilter, attempts]);

    return (
        <>
            <Spinner open={isLoading} />
            <Grid
                container
                display="flex"
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    container
                    item
                    width="100%"
                    mt={2}
                    p={1}
                    pr={2}
                    mb={2}
                    display="flex"
                    minHeight="60px"
                    borderRadius={"20px"}
                    alignItems={"center"}
                    sx={{
                        background: "linear-gradient(to left, #04598c 0%, #193256 100%)",
                        boxShadow: "10px 3px 10px -5px #262835",
                    }}
                >
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                        justifyContent="center"
                        sx={{
                            borderRadius: "30px 0px 30px 0px",
                            borderTopLeftRadius: "30px",
                            boxShadow: "2px 2px 14px #262835",
                            background: "linear-gradient(to right, #04598c 0%, #193256 150%)"
                        }}
                    >
                        <SettingsSuggestIcon fontSize="large" color="white" />
                    </Grid>
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                    >
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Typography color="white.main" mr={4}>Time Span: </Typography>
                        <Dropdown
                            id="time-span-dropdown"
                            items={timeOptions}
                            value={timeFilter}
                            onChange={(event) => setTimeFilter(event.target.value)}
                            size="small"
                            width="200px"
                            filled={false}
                            background="secondary"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Search value={searchFilter} onChange={(event) => setSearchFilter(event.target.value)} />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        pr={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        height="60px"
                    >
                        <Grid item className={classes.statistics}>
                            <Typography fontSize="small"><b>Statistics: </b>{`${filteredAttempts.length} attempts`}</Typography>
                            <Typography fontSize="small">
                                <b>Last check: </b>
                                {filteredAttempts?.[0]?.timestamp && dayjs(filteredAttempts?.[0]?.timestamp * 1000).fromNow()}
                                {!filteredAttempts?.[0]?.timestamp && "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={2} width="100%">
                    <Accordion
                        alwaysExpanded
                        title={(
                            <Grid
                                container
                                display="flex"
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <Typography>Hacking Attempts / Errors</Typography>
                                </Grid>
                            </Grid>
                        )}
                        subtitle={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={5}>
                                    <Typography>URL</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Attempts</Typography>
                                </Grid>
                                {/* <Grid item xs={3} textAlign="center">
                                    <Typography>Headers</Typography>
                                </Grid> */}
                                <Grid item xs={5} textAlign="center">
                                    <Typography>Example header</Typography>
                                </Grid>
                            </Grid>
                        )}
                        content={(
                            <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                {groupedAttempts.map((element, ind) => (
                                    <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                        {ind !== 0
                                            && (
                                                <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                    <Divider style={{ width: "100%" }} />
                                                </Grid>
                                            )}
                                        <Grid key={`row_${ind}`} container item alignItems="center">
                                            <Grid item xs={5} display="flex" alignItems="center">
                                                <Typography sx={{ wordBreak: "break-word" }}>{element[0]}</Typography>
                                            </Grid>
                                            <Grid item xs={2} textAlign="center">
                                                <Typography sx={{ wordBreak: "break-word" }}>{element[1]}</Typography>
                                            </Grid>
                                            <Grid item xs={5} textAlign="center">
                                                <Typography>{element[2]}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default memo(Hacking);
