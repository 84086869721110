import { Grid, Typography, Divider, ToggleButton } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';

import Accordion from "../../components/Accordion";
import Spinner from "../../components/Spinner";
import { useSnackbar } from "../../utils";
import { getR4alzSubmissions, exportR4alzSubmissions } from "../../api";

const R4AlzStats = () => {
    const { error } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [r4alzStats, setR4alzStats] = useState(null);
    const [r4alzPureStats, setR4alzPureStats] = useState(null);
    const fontSizeStats = 15;

    const downloadFile = (ex_type, data) => {
        const headers = []
        let parsedData = [];
        if(!(data.length > 0)){
            error("Collection has no data");
            return;
        }

        for(let d in data[0]) headers.push(d);
        parsedData.push(headers);
        for(let d of data){
            let tmpData = [];
            for(let key in d){
                tmpData.push(d[key]);
            }
            parsedData.push(tmpData);
        }

        const blob = new Blob([[...parsedData].join('\n')], { type: 'text/csv' })
    
        const a = document.createElement('a')
        a.download = 'submissions_' + ex_type + ".csv"
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const fetchData = useCallback(
        async () => {
            setIsLoading(true);
            try {
                const { success: successCode, stats: stats } = await getR4alzSubmissions();
                if (successCode) {
                    let parsed = {};

                    setR4alzPureStats(stats);

                    for (let i of stats.ex1.per_user) {
                        parsed[i._id] = { ex1: i.number, ex2: 0, ex3: 0, ex4: 0 };
                    }
                    for (let i of stats.ex2.per_user) {
                        if (!(i._id in parsed)) {
                            parsed[i._id] = { ex1: 0, ex2: i.number, ex3: 0, ex4: 0 };
                        }
                        else {
                            parsed[i._id].ex2 = i.number;
                        }
                    }
                    for (let i of stats.ex3.per_user) {
                        if (!(i._id in parsed)) {
                            parsed[i._id] = { ex1: 0, ex2: 0, ex3: i.number, ex4: 0 };
                        }
                        else {
                            parsed[i._id].ex3 = i.number;
                        }
                    }
                    for (let i of stats.ex4.per_user) {
                        if (!(i._id in parsed)) {
                            parsed[i._id] = { ex1: 0, ex2: 0, ex3: 0, ex4: i.number };
                        }
                        else {
                            parsed[i._id].ex4 = i.number;
                        }
                    }
                    let sortable = [];
                    for (var mail in parsed) {
                        sortable.push([mail, parsed[mail]]);
                    }
                    sortable.sort(function (a, b) {
                        return a[0].localeCompare(b[0]);
                    });

                    setR4alzStats(sortable);
                } else {
                    error();
                }
            } catch (_error) {
                console.log(_error)
                error();
            }

            setIsLoading(false);
        },
        []);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [fetchData]);

    return (
        <>
            <Spinner open={isLoading} />
            <Grid
                container
                display="flex"
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                {(r4alzStats != null &&
                    <>
                        {/* The main screen */}
                        <Grid
                            container
                            display="flex"
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item mt={3} width="100%">
                                <Accordion
                                    alwaysExpanded
                                    title={(
                                        <Grid
                                            container
                                            display="flex"
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item xs={12}>
                                                <Typography>Users</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    subtitle={(
                                        <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                            <Grid item xs={5}>
                                                <Typography>E-mail</Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign="center">
                                                <Typography>WorMem</Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign="center">
                                                <Typography>AttCont</Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign="center">
                                                <Typography>CogCont</Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign="center">
                                                <Typography>R4Alz-R</Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign="center">
                                                <Typography>Export</Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                    content={(
                                        <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                            {r4alzStats.map((element, ind) => (
                                                <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                                    {ind !== 0
                                                        && (
                                                            <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                                <Divider style={{ width: "100%", height: "0.2px", background: "third.main" }} />
                                                            </Grid>
                                                        )}
                                                    <Grid key={`row_${ind}`} container item alignItems="center">
                                                        <Grid item xs={5} display="flex" alignItems="center">
                                                            <Typography ml={1}>{element[0]}</Typography>
                                                        </Grid>
                                                        <Grid item xs={1} textAlign="center">
                                                            <Typography>{element[1].ex1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={1} textAlign="center">
                                                            <Typography>{element[1].ex2}</Typography>
                                                        </Grid>
                                                        <Grid item xs={1} textAlign="center">
                                                            <Typography>{element[1].ex3}</Typography>
                                                        </Grid>
                                                        <Grid item xs={1} textAlign="center">
                                                            <Typography>{element[1].ex4}</Typography>
                                                        </Grid>
                                                        <Grid item xs={3} textAlign="center">
                                                            <ToggleButton
                                                                value="View"
                                                                title="View"
                                                                size="small"
                                                                aria-label="View"
                                                                sx={{ borderColor: "white.main", ml: 1 }}
                                                                onClick={async () => {
                                                                    let res = await exportR4alzSubmissions("ex1", element[0]);
                                                                    downloadFile("ex1", res.stats);
                                                                }}
                                                            >
                                                                <LooksOneIcon color="white" />
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                value="View"
                                                                title="View"
                                                                size="small"
                                                                aria-label="View"
                                                                sx={{ borderColor: "white.main", ml: 1 }}
                                                                onClick={async () => {
                                                                    let res = await exportR4alzSubmissions("ex2", element[0]);
                                                                    downloadFile("ex2", res.stats);
                                                                }}
                                                            >
                                                                <LooksTwoIcon color="white" />
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                value="View"
                                                                title="View"
                                                                size="small"
                                                                aria-label="View"
                                                                sx={{ borderColor: "white.main", ml: 1 }}
                                                                onClick={async () => {
                                                                    let res = await exportR4alzSubmissions("ex3", element[0]);
                                                                    downloadFile("ex3", res.stats);
                                                                }}
                                                            >
                                                                <Looks3Icon color="white" />
                                                            </ToggleButton>
                                                            <ToggleButton
                                                                value="View"
                                                                title="View"
                                                                size="small"
                                                                aria-label="View"
                                                                sx={{ borderColor: "white.main", ml: 1 }}
                                                                onClick={async () => {
                                                                    let res = await exportR4alzSubmissions("ex4", element[0]);
                                                                    downloadFile("ex4", res.stats);
                                                                }}
                                                            >
                                                                <Looks4Icon color="white" />
                                                            </ToggleButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}

                <Divider />
                {(r4alzPureStats != null &&
                    <Grid
                        item
                        width="100%"
                        mt={2}
                    >
                        <Accordion
                            alwaysExpanded
                            title={(
                                <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}>
                                        <Typography>Last submissions - Working memory</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            subtitle={(
                                <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                    <Grid item xs={3}>
                                        <Typography>Examiner</Typography>
                                    </Grid>
                                    <Grid item xs={3} textAlign="center">
                                        <Typography>Date</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Examinee</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Status</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Anx/Dep</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            content={(
                                <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                    {r4alzPureStats.ex1.last.map((element, ind) => (
                                        <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                            {ind !== 0
                                                && (
                                                    <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                        <Divider style={{ width: "100%", height: "0.2px", background: "third.main" }} />
                                                    </Grid>
                                                )}
                                            <Grid key={`row_${ind}`} container item alignItems="center">
                                                <Grid item xs={3} display="flex" alignItems="center">
                                                    <Typography ml={1} fontSize={fontSizeStats}>{element.other_unit}</Typography>
                                                </Grid>
                                                <Grid item xs={3} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.createdAt}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.amka}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.status}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.depr_anx}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        />
                        <Accordion
                            alwaysExpanded
                            title={(
                                <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}>
                                        <Typography>Last submissions - Attention control</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            subtitle={(
                                <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                    <Grid item xs={3}>
                                        <Typography>Examiner</Typography>
                                    </Grid>
                                    <Grid item xs={3} textAlign="center">
                                        <Typography>Date</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Examinee</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Status</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Anx/Dep</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            content={(
                                <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                    {r4alzPureStats.ex2.last.map((element, ind) => (
                                        <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                            {ind !== 0
                                                && (
                                                    <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                        <Divider style={{ width: "100%", height: "0.2px", background: "third.main" }} />
                                                    </Grid>
                                                )}
                                            <Grid key={`row_${ind}`} container item alignItems="center">
                                                <Grid item xs={3} display="flex" alignItems="center">
                                                    <Typography ml={1} fontSize={fontSizeStats}>{element.other_unit}</Typography>
                                                </Grid>
                                                <Grid item xs={3} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.createdAt}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.amka}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.status}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.depr_anx}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        />
                        <Accordion
                            alwaysExpanded
                            title={(
                                <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}>
                                        <Typography>Last submissions - Cognitive control</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            subtitle={(
                                <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                    <Grid item xs={3}>
                                        <Typography>Examiner</Typography>
                                    </Grid>
                                    <Grid item xs={3} textAlign="center">
                                        <Typography>Date</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Examinee</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Status</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Anx/Dep</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            content={(
                                <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                    {r4alzPureStats.ex3.last.map((element, ind) => (
                                        <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                            {ind !== 0
                                                && (
                                                    <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                        <Divider style={{ width: "100%", height: "0.2px", background: "third.main" }} />
                                                    </Grid>
                                                )}
                                            <Grid key={`row_${ind}`} container item alignItems="center">
                                                <Grid item xs={3} display="flex" alignItems="center">
                                                    <Typography ml={1} fontSize={fontSizeStats}>{element.other_unit}</Typography>
                                                </Grid>
                                                <Grid item xs={3} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.createdAt}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.amka}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.status}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.depr_anx}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        />
                        <Accordion
                            alwaysExpanded
                            title={(
                                <Grid
                                    container
                                    display="flex"
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}>
                                        <Typography>Last submissions - R4alz-R</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            subtitle={(
                                <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                    <Grid item xs={3}>
                                        <Typography>Examiner</Typography>
                                    </Grid>
                                    <Grid item xs={3} textAlign="center">
                                        <Typography>Date</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Examinee</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Status</Typography>
                                    </Grid>
                                    <Grid item xs={2} textAlign="center">
                                        <Typography>Anx/Dep</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            content={(
                                <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                    {r4alzPureStats.ex4.last.map((element, ind) => (
                                        <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                            {ind !== 0
                                                && (
                                                    <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                        <Divider style={{ width: "100%", height: "0.2px", background: "third.main" }} />
                                                    </Grid>
                                                )}
                                            <Grid key={`row_${ind}`} container item alignItems="center">
                                                <Grid item xs={3} display="flex" alignItems="center">
                                                    <Typography ml={1} fontSize={fontSizeStats}>{element.other_unit}</Typography>
                                                </Grid>
                                                <Grid item xs={3} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.createdAt}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.amka}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.status}</Typography>
                                                </Grid>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography fontSize={fontSizeStats}>{element.depr_anx}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default memo(R4AlzStats);
