import { Grid, Typography, Divider, ToggleButton, FormControlLabel, Checkbox } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import Accordion from "../../components/Accordion";
import Search from "../../components/Search";
import Spinner from "../../components/Spinner";
import Dropdown from "../../components/Dropdown";
import Popup from "../../components/Popup";
import { isFuzzyMatch, useSnackbar } from "../../utils";
import { getServicesServicesData, getServicesCallData } from "../../api";

const useStyles = makeStyles(() => ({
    statistics: {
        maxWidth: "500px",
        background: "rgba(255, 255, 255, 1)",
        borderRadius: "10px",
        padding: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
}));

const timeOptions = [
    { text: "last day", value: "last day" },
    { text: "last week", value: "last week" },
    { text: "last 2 weeks", value: "last 2 weeks" },
    { text: "last month", value: "last month" },
    { text: "last 3 months", value: "last 3 months" },
    { text: "last 6 months", value: "last 6 months" },
    { text: "all", value: "all" },
];

const Services = () => {
    const classes = useStyles();
    const { error } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [excludeTests, setExcludeTests] = useState(true);
    const [timeFilter, setTimeFilter] = useState("last week");
    const [searchFilter, setSearchFilter] = useState("");
    const [attempts, setAttempts] = useState([]);
    const [filteredAttempts, setFilteredAttempts] = useState(attempts);
    const [totalCalls, setTotalCalls] = useState(0);
    const [viewCall, setViewCall] = useState("");
    const [viewCallResults, setViewCallResults] = useState(null);
    const [viewCallPopup, setViewCallPopup] = useState(false);

    function mode(array) {
        if (array.length == 0)
            return null;
        var modeMap = {};
        var maxEl = array[0], maxCount = 1;
        for (var i = 0; i < array.length; i++) {
            var el = array[i];
            if (modeMap[el] == null)
                modeMap[el] = 1;
            else
                modeMap[el]++;
            if (modeMap[el] > maxCount) {
                maxEl = el;
                maxCount = modeMap[el];
            }
        }
        return [maxEl, maxCount];
    }

    const fetchData = useCallback(
        async () => {
            setIsLoading(true);
            try {
                const { success: successCode, srvs: services } = await getServicesServicesData(timeFilter, excludeTests);
                let totCalls = 0;
                if (successCode) {
                    for (let s of services) {
                        s.full_name = s._id.group + "/" + s._id.call;
                        s.succ_perc = s.success.filter(x => x == true).length / s.success.length;
                        s.mean_time = s.time.filter(x => x > 0).reduce((a, b) => a + b, 0) / s.time.length;
                        s.dominant_name = mode(s.name);
                        totCalls += s.success.length;
                    }

                    setTotalCalls(totCalls);
                    setAttempts(services);
                } else {
                    error();
                }
            } catch (_error) {
                console.log(_error)
                error();
            }

            setIsLoading(false);
        },
        [timeFilter, excludeTests]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [fetchData]);

    useEffect(() => {
        setFilteredAttempts(attempts.filter((att) =>
            isFuzzyMatch(att.full_name, searchFilter)
            || isFuzzyMatch(att.succ_perc, searchFilter)
            || isFuzzyMatch(att.dominant_name, searchFilter)
        ));
    }, [searchFilter, attempts]);

    return (
        <>
            <Spinner open={isLoading} />

            {/* Call stats popup */}
            <Popup
                width="800px"
                title={"Stats for " + viewCall}
                open={viewCallPopup}
                onClose={() => {
                    setViewCallPopup(false);
                    setViewCallResults(null);
                }}
            >
                <Grid item width="100%">
                    <Accordion
                        alwaysExpanded
                        useShadow={false}
                        subtitle={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={4}>
                                    <Typography>Caller</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="center">
                                    <Typography>When</Typography>
                                </Grid>
                                <Grid item xs={3} textAlign="center">
                                    <Typography>Proc.Time (sec)</Typography>
                                </Grid>
                                <Grid item xs={1} textAlign="center">
                                    <Typography>Succ</Typography>
                                </Grid>
                            </Grid>
                        )}
                        content={(
                            viewCallResults &&
                            < Grid container width="100%" height="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                {Object.entries(viewCallResults.srvs).sort(([, a], [, b]) => b.timestamp - a.timestamp).filter((i) => i[0] != "total").map(([key, val]) => (
                                    <Grid item key={`comp_${key}`} flexDirection="column" width="100%">
                                        <Grid key={`row_${key}`} container item alignItems="center">
                                            <Grid item xs={4}>
                                                <Typography>{val.email}</Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign="center">
                                                <Typography>{(val.date.toISOString().replace("T", " ").replace("Z", ""))}</Typography>
                                            </Grid>
                                            <Grid item xs={3} textAlign="center">
                                                <Typography>{val.process_time ? (val.process_time > 0 ? val.process_time.toFixed(4) : '-') : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign="center">
                                                {(
                                                    val.success ?
                                                        <DoneAllIcon color="success" /> :
                                                        <ReportProblemIcon color="error" />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    />
                </Grid>
            </Popup>

            {/* The main screen */}
            <Grid
                container
                display="flex"
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    container
                    item
                    width="100%"
                    mt={2}
                    p={1}
                    pr={2}
                    mb={2}
                    display="flex"
                    minHeight="60px"
                    borderRadius={"20px"}
                    alignItems={"center"}
                    sx={{
                        background: "linear-gradient(to left, #04598c 0%, #193256 100%)",
                        boxShadow: "10px 3px 10px -5px #262835",
                    }}
                >
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                        justifyContent="center"
                        sx={{
                            borderRadius: "30px 0px 30px 0px",
                            borderTopLeftRadius: "30px",
                            boxShadow: "2px 2px 14px #262835",
                            background: "linear-gradient(to right, #04598c 0%, #193256 150%)"
                        }}
                    >
                        <SettingsSuggestIcon fontSize="large" color="white" />
                    </Grid>
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                    >
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Typography color="white.main" mr={4}>Time Span: </Typography>
                        <Dropdown
                            id="time-span-dropdown"
                            items={timeOptions}
                            value={timeFilter}
                            onChange={(event) => setTimeFilter(event.target.value)}
                            size="small"
                            width="200px"
                            filled={false}
                            background="secondary"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Search value={searchFilter} onChange={(event) => setSearchFilter(event.target.value)} />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        pl={2}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                    >
                        <FormControlLabel
                            control={<Checkbox sx={{
                                color: "#ffffff",
                                '&.Mui-checked': {
                                    color: "#ffffff",
                                },
                            }} defaultChecked />}
                            label={<Typography sx={{ color: "#ffffff" }}>{"Exclude tests"}</Typography>}
                            onChange={() => setExcludeTests(val => !val)}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        height="60px"
                        pr={3}
                    >
                        <Grid item className={classes.statistics}>
                            <Typography fontSize="small"><b>Statistics: </b>{`${filteredAttempts.length} services`}</Typography>
                            <Typography fontSize="small">
                                <b>Total calls: </b>
                                {totalCalls}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={2} width="100%">
                    <Accordion
                        alwaysExpanded
                        title={(
                            <Grid
                                container
                                display="flex"
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <Typography>ISSEL Services information</Typography>
                                </Grid>
                            </Grid>
                        )}
                        subtitle={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={3}>
                                    <Typography>Service</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>Calls Num</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>Mean Succ/Author</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>Mean Time</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>Most used</Typography>
                                </Grid>
                            </Grid>
                        )}
                        content={(
                            <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                {attempts.map((element, ind) => (
                                    <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                        {ind !== 0
                                            && (
                                                <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                    <Divider style={{ width: "100%" }} />
                                                </Grid>
                                            )}
                                        <Grid key={`row_${ind}`} container item alignItems="center">
                                            <Grid item xs={3} display="flex" alignItems="center">
                                                <Typography sx={{ wordBreak: "break-word" }}>{element.full_name}</Typography>
                                            </Grid>
                                            <Grid item xs={2} display="flex" alignItems="center" textAlign="center">
                                                <Typography sx={{ wordBreak: "break-word" }}>{element.success.length}</Typography>
                                            </Grid>
                                            <Grid item xs={2} display="flex" alignItems="center">
                                                <Typography sx={{ wordBreak: "break-word" }}>{element.succ_perc.toFixed(2) * 100.0 + " %"}</Typography>
                                            </Grid>
                                            <Grid item xs={2} display="flex" alignItems="center">
                                                <Typography sx={{ wordBreak: "break-word" }}>{element.mean_time > 0 ? element.mean_time.toFixed(5) : "-"}</Typography>
                                            </Grid>
                                            <Grid item xs={2} display="flex" alignItems="center">
                                                <Typography sx={{ wordBreak: "break-word" }}>{element.dominant_name[0] + " (" + element.dominant_name[1] + ")"}</Typography>
                                            </Grid>
                                            <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                                                <ToggleButton
                                                    value="View"
                                                    title="View"
                                                    size="small"
                                                    aria-label="View"
                                                    sx={{ borderColor: "secondary.main" }}
                                                    onClick={async () => {
                                                        const res = await getServicesCallData(timeFilter, excludeTests, element._id.call, element._id.group);
                                                        for (let c of res.srvs) {
                                                            c.date = new Date(c.timestamp * 1000);
                                                        }
                                                        console.log(res);

                                                        setViewCallResults(res);
                                                        setViewCall(element._id.call + "/" + element._id.group)
                                                        setViewCallPopup(true);
                                                    }}
                                                >
                                                    <VisibilityIcon color="secondary" />
                                                </ToggleButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default memo(Services);
