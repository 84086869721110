import { Grid, Typography, Divider, ToggleButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import KeyIcon from '@mui/icons-material/Key';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import DeleteIcon from "@mui/icons-material/Delete";
import { memo, useCallback, useEffect, useState } from "react";

import Accordion from "../../components/Accordion";
import {
    SecondaryBackgroundButton,
    SecondaryBorderButton,
    PrimaryBorderButton,
    PrimaryBackgroundButton,
} from "../../components/Buttons";
import Search from "../../components/Search";
import Spinner from "../../components/Spinner";
import { isFuzzyMatch, useSnackbar, dayjs } from "../../utils";
import { activateUser, deactivateUser, getServicesUsersData, removeUser, newUser, editUser } from "../../api";
import Popup from "../../components/Popup";

const Users = () => {
    const { error, success, info } = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [searchFilter, setSearchFilter] = useState("");
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [disableUser, setDisableUser] = useState({ id: null, name: null });
    const [enableUser, setEnableUser] = useState({ id: null, name: null });
    const [deleteUser, setDeleteUser] = useState({ id: null, name: null });

    const [newUserPopup, setNewUserPopup] = useState(false);
    const [newName, setNewName] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newAuthorizations, setNewAuthorizations] = useState("");
    const [newRestrictions, setNewRestrictions] = useState("");

    const [editUserFlag, setEditUserFlag] = useState(false);
    const [editId, setEditId] = useState(null);

    const [viewUserPopup, setViewUserPopup] = useState(false);
    const [viewUser, setViewUser] = useState(null);

    const fetchData = useCallback(
        async () => {
            setIsLoading(true);

            try {
                const { success: successCode, users: usrs } = await getServicesUsersData();
                if (successCode) {
                    setUsers(usrs);
                } else {
                    error();
                }
            } catch (_error) {
                error();
            }

            setIsLoading(false);
        },
        []);

    const onDisable = async () => {
        setIsLoading(true);

        const { success: successCode } = await deactivateUser(disableUser?.id);
        if (successCode) {
            success("User deactivated!");
        } else {
            error();
        }

        setDisableUser({ id: null, name: null });
        await fetchData();
        setIsLoading(false);
    };

    const onEnable = async () => {
        setIsLoading(true);

        const { success: successCode } = await activateUser(enableUser?.id);
        if (successCode) {
            success("User activated!");
        } else {
            error();
        }

        setEnableUser({ id: null, name: null });
        await fetchData();
        setIsLoading(false);
    };

    const onDelete = async () => {
        setIsLoading(true);

        const { success: successCode } = await removeUser(deleteUser?.id);
        if (successCode) {
            success("User deleted!");
        } else {
            error();
        }

        setDeleteUser({ id: null, name: null });
        await fetchData();
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [fetchData]);

    useEffect(() => {
        setFilteredUsers(users.filter((us) =>
            isFuzzyMatch(us.name, searchFilter)
            || isFuzzyMatch(us.email, searchFilter)
            || isFuzzyMatch(us.api_key, searchFilter)
            || isFuzzyMatch(us.creation_timestamp, searchFilter)
            || isFuzzyMatch(us.authorizations, searchFilter)
            || isFuzzyMatch(us.restrictions, searchFilter)
            || isFuzzyMatch(us.key_expiration_timestamp, searchFilter)
        ));
    }, [searchFilter, users]);

    const inputStyle = {
        color: "black",
        width: "100%",
        backgroundColor: "white",
        opacity: 0.7,
        borderRadius: "4px",
        margin: "0px",
        marginBottom: "10px",
    };

    const userChecks = () => {
        if (newName == "") {
            error("The name field cannot be empty");
            return false;
        }
        if (newEmail == "") {
            error("The email field cannot be empty");
            return false;
        }
        if (newAuthorizations == "") {
            error("The authorizations field cannot be empty");
            return false;
        }
        try {
            JSON.parse(newAuthorizations);
        } catch (e) {
            error("The authorizations field should contain a valid value (array with strings)!");
            return false;
        }
        try {
            JSON.parse(newRestrictions);
        } catch (e) {
            error("The restrictions field should contain a valid value (array with strings)!");
            return false;
        }
        return true;
    }

    const onNewSubmit = () => {
        if (!userChecks()) return;

        let data = {
            name: newName,
            email: newEmail,
            authorizations: JSON.parse(newAuthorizations),
            restrictions: JSON.parse(newRestrictions),
        }

        newUser(data).then((res) => {
            console.log(res)
            if (res.success == true) {
                info("User saved");
                setNewUserPopup(false);
                fetchData();
            }
            else {
                error(res.message);
            }
        })

    };

    const onEditSubmit = () => {
        if (!userChecks()) return;

        let data = {
            name: newName,
            email: newEmail,
            authorizations: JSON.parse(newAuthorizations),
            restrictions: JSON.parse(newRestrictions),
            id: editId
        }

        editUser(data).then((res) => {
            console.log(res)
            if (res.success == true) {
                info("User updated");
                setNewUserPopup(false);
                fetchData();
            }
            else {
                error(res.message);
            }
        })


    };

    return (
        <>
            <Spinner open={isLoading} />
            {/* New user popup */}
            <Popup
                width="600px"
                title={editUserFlag == false ? "New ISSEL services user" : "Edit user"}
                open={newUserPopup}
                backgroundColor="primary.main"
                onClose={() => setNewUserPopup(false)}
            >
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="filled-basic"
                        label="Name"
                        variant="filled"
                        style={inputStyle}
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                    />
                </Grid>
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="filled-basic"
                        label="E-mail"
                        variant="filled"
                        style={inputStyle}
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                    />
                </Grid>
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="filled-basic"
                        label="Authorizations"
                        variant="filled"
                        style={inputStyle}
                        value={newAuthorizations}
                        onChange={(e) => setNewAuthorizations(e.target.value)}
                    />
                </Grid>
                <Grid item width="100%" display="flex" flexDirection="row">
                    <TextField
                        id="filled-basic"
                        label="Restrictions"
                        variant="filled"
                        style={inputStyle}
                        value={newRestrictions}
                        onChange={(e) => setNewRestrictions(e.target.value)}
                    />
                </Grid>
                <Grid container justifyContent="flex-start">
                    {editUserFlag == false &&
                        <PrimaryBackgroundButton
                            width={"100%"}
                            title="Create"
                            onClick={() => onNewSubmit()}
                        />
                    }
                    {editUserFlag == true &&
                        <PrimaryBackgroundButton
                            width={"100%"}
                            title="Edit"
                            onClick={() => onEditSubmit()}
                        />
                    }
                </Grid>
            </Popup>

            {/* User stats popup */}
            <Popup
                width="800px"
                title={"Stats for " + viewUser?.name}
                open={viewUserPopup}
                onClose={() => setViewUserPopup(false)}
            >
                <Grid item width="100%">
                    <Accordion
                        alwaysExpanded
                        useShadow={false}
                        subtitle={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={8}>
                                    <Typography>Call name</Typography>
                                </Grid>
                                <Grid item xs={4} textAlign="center">
                                    <Typography>Count</Typography>
                                </Grid>
                            </Grid>
                        )}
                        content={(
                            viewUser && viewUser.calls &&
                            < Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                {Object.entries(viewUser.calls).sort(([, a], [, b]) => b - a).filter((i) => i[0] != "total").map(([key, val]) => (
                                    <Grid item key={`comp_${key}`} flexDirection="column" width="100%">
                                        <Grid key={`row_${key}`} container item alignItems="center">
                                            <Grid item xs={8}>
                                                <Typography>{key}</Typography>
                                            </Grid>
                                            <Grid item xs={4} textAlign="center">
                                                <Typography>{val}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid item my={1} width="100%">
                                    <Divider style={{ width: "100%" }} />
                                </Grid>
                                <Grid item key={`comp_total`} flexDirection="column" width="100%">
                                    <Grid key={`row_total`} container item alignItems="center">
                                        <Grid item xs={8}>
                                            <Typography fontWeight={"bold"}>{"Total"}</Typography>
                                        </Grid>
                                        <Grid item xs={4} textAlign="center">
                                            <Typography fontWeight={"bold"}>{viewUser.calls?.total}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    />
                </Grid>
            </Popup>

            {/* The main screen */}
            <Grid
                container
                display="flex"
                direction="column"
                alignItems="center"
                justifyContent="center"
            >

                <Grid
                    container
                    item
                    width="100%"
                    mt={2}
                    p={1}
                    pr={2}
                    mb={2}
                    display="flex"
                    minHeight="60px"
                    borderRadius={"20px"}
                    alignItems={"center"}
                    sx={{
                        background: "linear-gradient(to left, #04598c 0%, #193256 100%)",
                        boxShadow: "10px 3px 10px -5px #262835",
                    }}
                >
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                        justifyContent="center"
                        sx={{
                            borderRadius: "30px 0px 30px 0px",
                            borderTopLeftRadius: "30px",
                            boxShadow: "2px 2px 14px #262835",
                            background: "linear-gradient(to right, #04598c 0%, #193256 150%)"
                        }}
                    >
                        <SettingsSuggestIcon fontSize="large" color="white" />
                    </Grid>
                    <Grid
                        item
                        xs={0.5}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="60px"
                    >
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                    >
                        <Search value={searchFilter} onChange={(event) => setSearchFilter(event.target.value)} />
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        height="100%"
                    >
                        <PrimaryBorderButton title="Create New User" onClick={() => {
                            setEditUserFlag(false);
                            setNewName("");
                            setNewEmail("");
                            setNewAuthorizations("");
                            setNewRestrictions("");
                            setNewUserPopup(true);
                        }} />
                    </Grid>
                </Grid>
                <Grid item mt={2} width="100%">
                    <Accordion
                        alwaysExpanded
                        title={(
                            <Grid
                                container
                                display="flex"
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid item xs={12}>
                                    <Typography>Users</Typography>
                                </Grid>
                            </Grid>
                        )}
                        subtitle={(
                            <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" spacing={0}>
                                <Grid item xs={2}>
                                    <Typography>Name</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Created At</Typography>
                                </Grid>
                                <Grid item xs={2.5} textAlign="center">
                                    <Typography>Authorizations</Typography>
                                </Grid>
                                <Grid item xs={2.5} textAlign="center">
                                    <Typography>Restrictions</Typography>
                                </Grid>
                                <Grid item xs={1} textAlign="center">
                                    <Typography>Calls</Typography>
                                </Grid>
                                <Grid item xs={2} textAlign="center">
                                    <Typography>Actions</Typography>
                                </Grid>
                            </Grid>
                        )}
                        content={(
                            <Grid container width="100%" display="flex" direction="column" alignItems="center" justifyContent="center" spacing={0}>
                                {filteredUsers.map((us, ind) => (
                                    <Grid item key={`comp_${ind}`} flexDirection="column" width="100%">
                                        {ind !== 0
                                            && (
                                                <Grid key={`divider_${ind}`} item my={1} width="100%">
                                                    <Divider style={{ width: "100%" }} />
                                                </Grid>
                                            )}
                                        <Grid key={`row_${ind}`} container item alignItems="center">
                                            <Grid item xs={2} display="flex" alignItems="center">
                                                <Grid
                                                    backgroundColor={(us.active) ? "success.main" : "error.main"}
                                                    sx={{ width: "15px", height: "15px", borderRadius: "15px" }}
                                                />
                                                <Typography ml={1}>{us.name}</Typography>
                                            </Grid>
                                            <Grid item xs={2} textAlign="center">
                                                <Typography>{dayjs(us.key_update_timestamp).format('DD/MM/YYYY HH:mm')}</Typography>
                                            </Grid>
                                            <Grid item xs={2.5} textAlign="center">
                                                <Typography>{JSON.stringify(us.authorizations)}</Typography>
                                            </Grid>
                                            <Grid item xs={2.5} textAlign="center">
                                                <Typography>{JSON.stringify(us.restrictions)}</Typography>
                                            </Grid>
                                            <Grid item xs={1} textAlign="center">
                                                <Typography>{us.calls.total}</Typography>
                                            </Grid>
                                            <Grid item xs={2} display="flex" justifyContent="space-evenly">
                                                <ToggleButton
                                                    value="Key"
                                                    title="Copy key"
                                                    size="small"
                                                    aria-label="Key"
                                                    sx={{ borderColor: "success.main" }}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(us.api_key);
                                                        success("API key copied to clipboard");
                                                    }}
                                                >
                                                    <KeyIcon color="success" />
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="View"
                                                    title="View"
                                                    size="small"
                                                    aria-label="View"
                                                    sx={{ borderColor: "white.main" }}
                                                    onClick={() => {
                                                        setViewUser(us);
                                                        setViewUserPopup(true);
                                                    }}
                                                >
                                                    <VisibilityIcon color="white" />
                                                </ToggleButton>
                                                <ToggleButton
                                                    value="Edit"
                                                    title="Edit"
                                                    size="small"
                                                    aria-label="Edit"
                                                    sx={{ borderColor: "secondary.main" }}
                                                    onClick={() => {
                                                        setEditUserFlag(true);
                                                        setNewName(us.name);
                                                        setNewEmail(us.email);
                                                        setNewAuthorizations(
                                                            JSON.stringify(us.authorizations)
                                                        );
                                                        setNewRestrictions(
                                                            JSON.stringify(us.restrictions)
                                                        );
                                                        setEditId(us._id);
                                                        setNewUserPopup(true);
                                                    }}
                                                >
                                                    <EditIcon color="secondary" />
                                                </ToggleButton>
                                                {us.active
                                                    && (
                                                        <ToggleButton
                                                            value="Disable"
                                                            title="Disable"
                                                            size="small"
                                                            aria-label="Disable"
                                                            sx={{ borderColor: "warning.main" }}
                                                            onClick={() => setDisableUser({ id: us._id, name: us.name })}
                                                        >
                                                            <PersonRemoveIcon color="warning" />
                                                        </ToggleButton>
                                                    )}
                                                {!us.active
                                                    && (
                                                        <ToggleButton
                                                            value="Enable"
                                                            title="Enable"
                                                            size="small"
                                                            aria-label="Enable"
                                                            sx={{ borderColor: "success.main" }}
                                                            onClick={() => setEnableUser({ id: us._id, name: us.name })}
                                                        >
                                                            <PersonAddIcon color="success" />
                                                        </ToggleButton>
                                                    )}
                                                <ToggleButton
                                                    value="Delete"
                                                    title="Delete"
                                                    size="small"
                                                    aria-label="Delete"
                                                    sx={{ borderColor: "error.main" }}
                                                    onClick={() => setDeleteUser({ id: us._id, name: us.name })}
                                                >
                                                    <DeleteIcon color="error" />
                                                </ToggleButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    />
                </Grid>
            </Grid>
            <Dialog
                open={disableUser?.id ? true : false}
                onClose={() => setDisableUser({ id: null, name: null })}
            >
                <DialogTitle>
                    {"Deactivate user?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Are you sure you want to deactivate the user ${disableUser?.name}? `}
                        {"The user will not be able to perform any more requests!"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryBorderButton onClick={() => setDisableUser({ id: null, name: null })} title="Cancel" />
                    <SecondaryBackgroundButton onClick={onDisable} title="Disable" />
                </DialogActions>
            </Dialog>
            <Dialog
                open={enableUser?.id ? true : false}
                onClose={() => setEnableUser({ id: null, name: null })}
            >
                <DialogTitle>
                    {"Activate user?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Are you sure you want to activate the user ${enableUser?.name}? `}
                        {"The user will now be able to perform requests!"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryBorderButton onClick={() => setEnableUser({ id: null, name: null })} title="Cancel" />
                    <SecondaryBackgroundButton onClick={onEnable} title="Enable" />
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteUser?.id ? true : false}
                onClose={() => setDeleteUser({ id: null, name: null })}
            >
                <DialogTitle>
                    {"Delete user?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Are you sure you want to delete the user ${deleteUser?.name}? `}
                        {"The user will not be able to perform any more requests!"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SecondaryBorderButton onClick={() => setDeleteUser({ id: null, name: null })} title="Cancel" />
                    <SecondaryBackgroundButton onClick={onDelete} title="Delete" />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default memo(Users);
